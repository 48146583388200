import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { organizationAtom, activeOrganizationAtom } from '../../recoil/organization-atom';
import { userAtom } from '../../recoil/userAtoms';
import { activeWorkspaceAtom } from '../../recoil/workspaceAtom';

function BreadCrumb({timelineId}) {
  const [user, setUser] = useRecoilState(userAtom);
  const [, setUserOrganizations] = useRecoilState(organizationAtom);
  const [activeOrganization, setActiveOrganization] = useRecoilState(activeOrganizationAtom);
  const navigate = useNavigate();
  const [activeWorkspace] = useRecoilState(activeWorkspaceAtom);

  // get page path
  const urlPath = window.location.pathname.split('/')[1];

  const handleActiveWorkspaceClick = () => {
    if (urlPath === 'workspace') return;
    navigate('/workspace/' + activeWorkspace._id);
  }

  const handleTimelineClick = () => {
    if (urlPath === 'approvalRequest') return;
    navigate('/approvalRequest/' + timelineId);
  }

  return (
    <div className="flex gap-2 items-stretch">
      <span className="self-center cursor-pointer" onClick={() => navigate('/workspaces')}>Workspaces</span>
      <span className="self-center">{'>'}</span>
      <span className="font-bold py-0.5 px-1 rounded-lg bg-[#F9FAFB] self-center cursor-pointer" onClick={handleActiveWorkspaceClick}>
        {activeWorkspace?.name}
      </span>
      {
        timelineId &&
        <>
          <span className="self-center">{'>'}</span>
          <span className="self-center cursor-pointer" onClick={handleTimelineClick}>Timeline</span>
        </>
      }
    </div>
  );
}

export default BreadCrumb;
