// function darkerShade(hexColor, darkenPercent) {
//     // Ensure darkenPercent is between 0 and 1
//     darkenPercent = Math.min(Math.max(darkenPercent, 0), 1);

//     // Convert hex to RGB
//     let r = parseInt(hexColor?.substring(1, 3), 16);
//     let g = parseInt(hexColor?.substring(3, 5), 16);
//     let b = parseInt(hexColor?.substring(5, 7), 16);

//     // Calculate darker color
//     r = Math.floor(r * (1 - darkenPercent));
//     g = Math.floor(g * (1 - darkenPercent));
//     b = Math.floor(b * (1 - darkenPercent));

//     // Convert back to hex and return
//     return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
//   }

function darkerShade(hexColor, darkenPercent) {
  if (!hexColor || hexColor.length !== 7 || hexColor[0] !== "#")
    return "#000000"; // Default black for invalid inputs

  // Ensure darkenPercent is between 0 and 1
  darkenPercent = Math.min(Math.max(darkenPercent, 0), 1);

  // Convert hex to RGB
  let r = parseInt(hexColor.substring(1, 3), 16);
  let g = parseInt(hexColor.substring(3, 5), 16);
  let b = parseInt(hexColor.substring(5, 7), 16);

  // Calculate darker color
  r = Math.max(0, Math.floor(r * (1 - darkenPercent)));
  g = Math.max(0, Math.floor(g * (1 - darkenPercent)));
  b = Math.max(0, Math.floor(b * (1 - darkenPercent)));

  // Convert back to hex
  return `#${r.toString(16).padStart(2, "0")}${g
    .toString(16)
    .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
}

function generateUserColor(email = '') {
  // Hash email to get a consistent color
  let hash = 0;
  for (let i = 0; i < email.length; i++) {
    hash = email.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Convert hash to hex color
  const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, "0")}${(
    (hash >> 16) &
    0xff
  )
    .toString(16)
    .padStart(2, "0")}${((hash >> 8) & 0xff).toString(16).padStart(2, "0")}`;

  return color;
}

export { darkerShade, generateUserColor };
