import React, { useState, useRef } from "react";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import CheckCircle from "../SVGs/CheckCircle";
import successIcon from "../../icons/successIcon.png";
import downloadIcon from "../../icons/downloadIcon.png";
import { useRecoilState } from "recoil";
import { userAtom } from "../../recoil/userAtoms";

const TwoFactorAuthModal = ({
  onClose,
  loading,
  setLoading,
  setPhoneNumber,
  phoneNumber,
  setMaskedNumber,
  maskedNumber,
  setIsSMS2FAEnabled,
  isSMS2FAEnabled,
  maskedEmail,
  setMaskedEmail,
  setIsEmail2FAEnabled,
  setStage,
  stage,
  isEmail2FAEnabled,
  isEmail,
  twoFAChannel,
  resendCode,
}) => {
  const [user, setUser] = useRecoilState(userAtom);
  const [backupCodes, setBackupCodes] = useState([]);
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const inputsRef = useRef([]);

  const isPhoneNumberValid = phoneNumber.trim().length >= 10;
  const isVerificationCodeValid = verificationCode.every(
    (digit) => digit !== ""
  );

  const toast = useToast();

  const handleContinue = () => {
    if (isPhoneNumberValid) {
      setStage(2); // Move to stage 2
    }
  };

  const handleVerificationCodeChange = (value, index) => {
    if (value.length <= 1 && /^[0-9]*$/.test(value)) {
      const updatedCode = [...verificationCode];
      updatedCode[index] = value;
      setVerificationCode(updatedCode);

      if (value && index < 5) {
        inputsRef.current[index + 1]?.focus();
      }
    }
  };

  const handleBackspace = (index) => {
    const updatedCode = [...verificationCode];
    updatedCode[index] = "";
    setVerificationCode(updatedCode);

    if (index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  const handleVerificationCodeSubmit = async () => {
    if (!isVerificationCodeValid) {
      toast({
        title: "Invalid code",
        description: "Please enter a valid 6-digit code",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
      
    if (isSMS2FAEnabled) {
      setStage(3);
      setIsSMS2FAEnabled(true);
      setMaskedNumber(maskedNumber);
    } else {
      await handleSubmitCode()
      // setStage(3);
      // setIsEmail2FAEnabled(true);
      // setMaskedEmail(maskedEmail);
    }

    // setTimeout(() => {
    //   onClose(true);
    // }, 2000);
  };

  const handleDownloadBackupCodes = () => {
    const backupCodesText = backupCodes.join(' ');

    const blob = new Blob([backupCodesText], { type: 'text/plain' });
  
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'backup_codes.txt';
  
    document.body.appendChild(link);
    link.click();
  
    document.body.removeChild(link);
  };

  const handleSubmitCode = async () => {
    try {
      if (verificationCode.length < 6) {
        toast({
          title: "Invalid code",
          description: "Please enter a valid 6-digit code",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      // Call backend API to verify the code
      const { data } = await axios.put("/api/auth/2fa/verify", {
        code: verificationCode.join(""),
        channel: twoFAChannel,
        firebaseAuthUUID: user.firebaseAuthUUID,
      });

      if (twoFAChannel === "EMAIL") {
        setUser((prev) => ({
          ...prev,
          email2FAEnabled: true,
        }));
      } else {
        setUser((prev) => ({
          ...prev,
          sms2FAEnabled: true,
        }));
      }

      setBackupCodes(data.backupCodes);

      // If successful, proceed to the next stage
      setStage(3);
    } catch (error) {
      toast({
        title: "Invalid code",
        description: error.response?.data?.message || "Failed to verify the code",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error("Failed to verify the code:", error);
    }
  }

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-[30%] p-6">
        <div className="flex justify-between items-center mb-4">
          <CheckCircle />
          <button
            className="text-gray-400  text-2xl hover:text-gray-600 transform translate-y-[-4px]"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        {stage === 1 && (
          <>
            <div className="mb-6">
              <h2 className="text-lg font-semibold">
                Provide your phone number
              </h2>
              <p className="text-sm text-gray-600 mb-4">
                Please enter the phone number associated with your mobile phone.
                We’ll send a verification code to your mobile phone.
              </p>
            </div>
            <div className="flex flex-col mb-6 mt-4">
              <div className="flex w-full space-x-4">
                <div className="flex flex-col w-1/3">
                  <label
                    htmlFor="countryCode"
                    className="text-sm font-medium text-gray-700 mb-1"
                  >
                    Country Code
                  </label>
                  <select
                    id="countryCode"
                    className="border border-gray-300 rounded-md px-3 py-2 text-sm"
                    defaultValue="+1"
                  >
                    <option value="+1">🇺🇸 +1</option>
                    <option value="+44">🇬🇧 +44</option>
                    <option value="+91">🇮🇳 +91</option>
                    {/* Add more country codes as needed */}
                  </select>
                </div>
                <div className="flex flex-col w-2/3">
                  <label
                    htmlFor="phoneNumber"
                    className="text-sm font-medium text-gray-700 mb-1"
                  >
                    Phone Number
                  </label>
                  <input
                    id="phoneNumber"
                    type="text"
                    className="flex-grow border border-gray-300 rounded-md px-3 py-2 text-sm"
                    placeholder="000-0000-0000"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="flex gap-2 pt-4 items-start self-stretch">
              <button
                className="text-gray-600 border border-gray-300 px-4 py-2 rounded-md hover:bg-gray-100 w-full mb-4"
                onClick={onClose}
              >
                Back
              </button>
              <button
                className={`px-4 py-2 rounded-md w-full ${
                  isPhoneNumberValid
                    ? "bg-blue-600 text-white hover:bg-blue-700"
                    : "bg-blue-200 border text-white cursor-not-allowed"
                }`}
                onClick={handleContinue}
                disabled={!isPhoneNumberValid}
              >
                Continue
              </button>
            </div>
          </>
        )}

        {stage === 2 && (
          <>
            <div className="mb-6">
              <h2 className="text-lg font-semibold">Enter verification code</h2>
              <p className="text-sm text-gray-600 font-normal mb-4">
                A message with a 6-digit code has been sent to{" "}
                {isEmail2FAEnabled ? maskedEmail : maskedNumber}. Please enter
                the code below to verify your identity.
              </p>
            </div>
            <div className="mb-4">
              <label
                htmlFor="verificationCode"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Verification Code
              </label>
              <div
                id="verificationCode"
                className="flex items-center justify-between mb-6 space-x-2"
              >
                {verificationCode.map((digit, index) => (
                  <React.Fragment key={index}>
                    <input
                      key={index}
                      ref={(el) => (inputsRef.current[index] = el)}
                      type="text"
                      maxLength="1"
                      placeholder="0"
                      className={`w-14 h-14 text-blue-600 text-center border border-2 border-gray-300 rounded-md text-lg font-semibold ${
                        digit ? "border-blue-800" : "border-gray-300"
                      }`}
                      value={digit}
                      onChange={(e) =>
                        handleVerificationCodeChange(e.target.value, index)
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Backspace") handleBackspace(index);
                      }}
                    />
                    {index === 2 && (
                      <span className="w-8 h-1 bg-gray-300 mx-3 rounded"></span>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="flex gap-2 pt-4 items-start self-stretch">
              <button
                className="text-blue-800 border text-sm border-gray-300 px-1.2 py-2.5 rounded-md bg-blue-100 hover:bg-blue-300 w-[100%] mb-4"
                onClick={isEmail2FAEnabled ? ()=> {}: () => setStage(1)} // Go back to stage 1
              >
                Didn't get a code? Click to resend.
              </button>
              <button
                className={`px-4 py-2 rounded-md w-full ${
                  isVerificationCodeValid
                    ? "bg-blue-600 text-white hover:bg-blue-700"
                    : "bg-blue-200 border text-white cursor-not-allowed"
                }`}
                onClick={handleSubmitCode}
                disabled={!isVerificationCodeValid}
              >
                Submit
              </button>
            </div>
          </>
        )}

        {stage === 3 && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg w-[30%] p-6">
              <div className="flex justify-end">
                <button
                  className="text-gray-400 text-2xl hover:text-gray-600 transform translate-y-[-4px]"
                  onClick={onClose}
                >
                  &times;
                </button>
              </div>
              <div className="text-center">
                <img
                  src={successIcon}
                  alt="success icon"
                  className="mx-auto mb-4 text-green-500 w-28 h-28"
                />
                <h2 className="text-lg font-semibold mb-2">
                  Two-factor authentication enabled successfully
                </h2>
                <div className="relative w-full">
                  {/* <img
                    src={downloadIcon}
                    alt="download icon"
                    className="absolute mt-8 left-4 transform px-4 py-1 ml-24"
                  /> */}
                  <p className="text-sm text-gray-600">
                    Backup codes have been generated for your account. Please
                    download them and keep them safe or write them down in the order they appear.
                  </p>
                  <ul className="text-sm text-gray-600 mt-4 flex gap-2 flex-wrap justify-center">
                    {backupCodes.map((code, index) => (
                      <li className="bg-gray-200 px-2 rounded" key={index}>{code}</li>
                    ))}
                  </ul>
                  <button
                    className="bg-blue-50 text-blue-800 text-md border border-blue-200 font-semibold px-4 py-2 rounded-md hover:bg-blue-300 transition w-full mt-6"
                    onClick={handleDownloadBackupCodes}
                  >
                    Download backup codes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TwoFactorAuthModal;
