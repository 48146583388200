import Icon from '../Icon';
import TableCell from './TableCell';
import editIcon from '../../icons/edit-icon.png';
import deleteIcon from '../../icons/trash-icon.png';
import membersIcon from '../../icons/membersIcon.png'
import { useEffect, useState } from 'react';

const TableRow = ({
  onSelect,
  fields = [],
  data,
  editAction,
  deleteAction,
  showActions,
  viewMembersAction,
  isSelected,
  navigate,
  cols,
  tableType,
}) => {
  return (
    <tr
      className={`grid border-t py-3 group justify-baseline grid-flow-col auto-cols-max`}
      style={{
        gridAutoColumns: 'max-content',
        gridTemplateColumns: `repeat(${
          cols || fields.length + (showActions ? 1 : 0)
        }, minmax(0, 1fr))`,
      }}
    >
      {fields.map(({ key }, index) =>
        data[key]?.children ? (
          <TableCell
            key={index}
            value={data[key].value}
            children={data[key]?.children}
            span={data[key]?.span}
            {...(index === 0 && {
              onSelect,
              selectId: data.id,
              isSelected,
              navigate,
            })}
          />
        ) : (
          <TableCell
            key={index}
            value={data[key]}
            span={data[key]?.span}
            {...(index === 0 && {
              onSelect,
              selectId: data.id,
              isSelected,
              navigate,
            })}
          />
        )
      )}
      {showActions && (
        <TableCell>
          <div className="flex gap-2 md:gap-4 justify-end px-6 hidden group-hover:flex">
            {tableType ? (
              <>
                {editAction && (
                  <button
                    onClick={editAction}
                    className="text-blue-600 hover:underline"
                  >
                    Edit
                  </button>
                )}
                {deleteAction && (
                  <button
                    onClick={deleteAction}
                    className="text-red-600 hover:underline"
                  >
                    Remove
                  </button>
                )}
              </>
            ) : (
              <>
                {viewMembersAction ? (
                  <Icon
                    src={membersIcon}
                    alt="viewMembers"
                    onClick={viewMembersAction}
                    size={4}
                  />
                ) : null}
                {deleteAction ? (
                  <Icon
                    src={deleteIcon}
                    alt="edit"
                    onClick={deleteAction}
                    size={4}
                  />
                ) : null}
                {editAction ? (
                  <Icon
                    src={editIcon}
                    alt="edit"
                    onClick={editAction}
                    size={4}
                  />
                ) : null}
              </>
            )}
          </div>
        </TableCell>
      )}
    </tr>
  );
};

export default TableRow;
