function validateLink(url){
    // Basic regex for HTTPS URL validation
    const regex = /^https:\/\/([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
    return regex.test(url);
};

function validateEmail(email){
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex, you might want to use a more complex one depending on your needs
    return re.test(email);
  };

const validateEmailOrToast = (email, toast) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (!emailRegex.test(email)) {
    toast({
      title: "Error",
      description: "Email address not valid",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
    return false;
  }
  return true;
};


  function isCharCoumtOverLimit(str){
    if(str?.length > 150){
      return true;
    }
    return false ;
  }

const isEmptyObject =  (obj) => {
  return Object.keys(obj).length === 0;
}

const validateRequest = (
  approvalTitle,
  dueDate,
  description,
  selectedApprovals,
  htmlContents,
  attachments
) => {
  const missingFields = [];

  if (!approvalTitle) missingFields.push('Approval Title');
  if (!dueDate) missingFields.push('Due Date');
  if (!description) missingFields.push('Description');
  if (!htmlContents[0]) missingFields.push('Content');
  if (selectedApprovals.length === 0) missingFields.push('Approvers');

  return missingFields;
};

export { validateLink, validateEmail, isCharCoumtOverLimit, validateEmailOrToast, isEmptyObject, validateRequest };