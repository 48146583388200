import React, { useState } from "react";
import { useRecoilState } from "recoil";
import axios from "axios";
import { tempUserAtom, userAtom } from "../recoil/userAtoms";
import { useNavigate } from "react-router-dom";
import AuthScreen from "../components/AuthScreens";
import FormInput from "../components/Form/FormInput";
import Spinner from "../components/Spinner/Spinner";
import { useToast } from "@chakra-ui/react";


const Verify2FaAndLogin = () => {
  const navigate = useNavigate();
  const toast = useToast()
  const [tempUser, setTempUser] = useRecoilState(tempUserAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const urlParams = new URLSearchParams(window.location.search);
  const redirectLink = urlParams.get('login_to');
  const history = useNavigate();
  const [validationError, setValidationError] = useState("");
  const [verificationError, setVerificationError] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [reset2Fa, setReset2Fa] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false); // New state for email input
  const [backupCode, setBackupCode] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [resetToken, setResetToken] = useState("");

  const validateForm = () => {
    if (reset2Fa) {
      if (showEmailInput) {
        return (
          email.trim().length > 0 &&
          (!isEmailSent || resetToken.trim().length > 0)
        );
      }
      return true;
    }
    return verificationCode.trim().length > 0;
  };

  const handleReset2FA = async (useEmail = true) => {
    // Call backend API to reset 2FA
    await axios.post('/api/auth/2fa/reset', useEmail ? {
      email
    }:{ backupCodes: backupCode});

    setIsEmailSent(true);
  };

 const handleVerificationSubmit = async (e) => {
   e.preventDefault();
   if (!reset2Fa) {
     if (verificationCode.length < 6) {
       setVerificationError(
         "Incorrect verification code. Make sure you input the code sent to your email address."
       );
     } else {
       setVerificationError("");
       // Proceed with verification
      await axios.post('/api/auth/2fa/login', {
        userId: tempUser._id, code: verificationCode, channel:'EMAIL'
      }).then((response) => {
        setUser(response.data.user);
        // Handle the response as needed, for example:
        // This function creates visitors and accounts in Pendo
        // You will need to replace <visitor-id-goes-here> and <account-id-goes-here> with values you use in your app
        // Please use Strings, Numbers, or Bools for value types.
        window.pendo.initialize({
          visitor: {
            id: response.data.user._id,
            email: response.data.user.email, // Recommended if using Pendo Feedback, or NPS Email
            full_name: response.data.user.fullname, // Recommended if using Pendo Feedback
          },
          account: {
            id: response.data.user._id,
          },
        });

        if (response.data.user.plan === 'freeTrial') {
          history('/select-plan');
        } else {
          if (redirectLink) {
            window.location.href = redirectLink;
          } else {
            history('/workspaces');
          }
        }

        // setIsLoading(false);
      }).catch(err => {
        console.error(err);
        toast({
          title: "An error occurred.",
          description: err.response.data.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    }
   } else if (!showEmailInput) {
    await handleReset2FA(false);
  } else if (showEmailInput) {
     await handleReset2FA();
   }
 };

  const handleResetChange = () => {
    setReset2Fa(true);
    setShowEmailInput(false);
    setBackupCode("");
  };

  const handleShowEmailInput = () => {
    setShowEmailInput(true);
  };

  if (!tempUser._id) {
    history("/login");
  }

  return (
    <AuthScreen>
      <div className="flex flex-col gap-8 sm:mx-28 sm:w-[360px]">
        {reset2Fa ? (
          <div className="flex flex-col gap-3">
            <h1 className="text-3xl font-normal">Reset 2FA</h1>
            <p className="text-sm">
              {reset2Fa
                ? isEmailSent
                  ? "An email has been sent to your email address. Check the inbox of your email account for the reset token provided."
                  : showEmailInput ? "Enter your email address to get a 2FA reset token"
                : "Enter you backup code to reset your 2FA setup" : null }
            </p>
            {/* <p className="text-sm">
              An email has been sent to your email address. Check the inbox of
              your email account for the reset token provided.
            </p> */}
          </div>
        ) : (
          <div className="flex flex-col gap-3">
            <h1 className="text-3xl font-normal">Two factor authentication</h1>
            <p className="text-sm">
              Please input in the code sent to your email
            </p>
          </div>
        )}
        <form
          className="flex flex-col gap-2 sm:gap-2"
          onSubmit={handleVerificationSubmit}
        >
          {reset2Fa ? (
            showEmailInput ? (
              <>
                <FormInput
                  label="Email"
                  id="email"
                  type="text"
                  value={email}
                  placeholder="Enter email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {/* {isEmailSent && (
                  <FormInput
                    label="Reset Token"
                    id="resetToken"
                    type="text"
                    value={resetToken}
                    placeholder="Enter reset token"
                    onChange={(e) => setResetToken(e.target.value)}
                  />
                )} */}
              </>
            ) : (
              <FormInput
                label="Backup Code"
                id="backupCode"
                type="text"
                placeholder="Enter backup code"
                value={backupCode || ""}
                onChange={(e) => setBackupCode(e.target.value)}
              />
            )
          ) : (
            <div className="flex flex-col">
              <FormInput
                label="Verification Code"
                id="verificationCode"
                type="text"
                placeholder="Enter verification code"
                value={verificationCode}
                onChange={(e) => {
                  setVerificationCode(e.target.value);
                  setVerificationError("");
                }}
                className={`${
                  verificationError ? "border-red-500" : "border-gray-300"
                } border rounded p-2`}
              />
              {verificationError && (
                <p className="text-red-500 text-sm mt-1">{verificationError}</p>
              )}
            </div>
          )}
          <div className="flex items-center justify-between gap-2 mt-4">
            <button
              className="w-full text-black font-semibold bg-white-600 border py-2 rounded-md sm:h-11 text-sm"
              type="button"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
            <button
              className={`w-full  font-semibold text-white py-2 rounded-md sm:h-11 text-sm ${
                validateForm()
                  ? "bg-primary-600"
                  : "bg-blue-200 cursor-not-allowed"
              }`}
              type="submit"
              disabled={!validateForm()}
            >
              Continue
            </button>
          </div>
        </form>
        <div className="items-center text-xs text-gray-500">
          {reset2Fa ? (
            isEmailSent ? (
              <p className="cursor-pointer">
                Didn't recieve an email?{" "}
                <span
                  className="text-blue-800"
                  onClick={() => setIsEmailSent(false)}
                >
                  Retry
                </span>
              </p>
            ) : (
              <p className="cursor-pointer">
                Don't have your backup code?{" "}
                <span className="text-blue-800" onClick={handleShowEmailInput}>
                  Click here
                </span>
              </p>
            )
          ) : (
            <p className="cursor-pointer">
              Don't have access to your email?{" "}
              <span className="text-blue-800" onClick={handleResetChange}>
                Click here to reset
              </span>
            </p>
          )}
        </div>
      </div>
    </AuthScreen>
  );
};

export default Verify2FaAndLogin;
