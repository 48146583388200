import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { tempUserAtom, userAtom } from '../recoil/userAtoms';
import { useNavigate } from 'react-router-dom';
import AuthScreen from '../components/AuthScreens';
import FormInput from '../components/Form/FormInput';
import Spinner from '../components/Spinner/Spinner';
import { useToast } from '@chakra-ui/react';

const Reset2FA = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');

  const [loading, setLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    axios
      .put('/api/auth/2fa/disable', {
        token,
      })
      .then((response) => {
        setIsSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        toast({
          title: 'An error occurred.',
          description: error.response?.data?.message ?? 'Invalid token',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setLoading(false);
      });
  }, []);

  return (
    <AuthScreen>
      <div className="flex flex-col gap-8 sm:mx-28 sm:w-[360px]">
        <div className="flex flex-col justify-center items-center gap-8">
          {loading ? (
            <Spinner />
          ) : isSuccess ? (
            <p>
              Your request to reset you 2FA setup is successful. You can
              continue to{' '}
              <span
                className="cursor-pointer text-blue-800"
                onClick={() => navigate('/login')}
              >
                login
              </span>{' '}
              with your email and password.
            </p>
          ) : (
            <p>
              We could not reset your 2FA setup. Please check your link and try
              again or restart the process.{' '}
              <span
                className="cursor-pointer text-blue-800"
                onClick={() => navigate('/login')}
              >
                Login
              </span>
            </p>
          )}
        </div>
      </div>
    </AuthScreen>
  );
};

export default Reset2FA;
