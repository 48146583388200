import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userAtom } from "../recoil/userAtoms";
import PageWrapper from "../components/PageWrapper";
import userIcon from "../icons/manage-user-icon.png";
import FormInput from "../components/Form/FormInput";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { validateEmailOrToast } from "../utils/validateUtils";
import uploadToS3 from "../utils/uploadToS3";
import axios from "axios";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { firebaseApp } from "../firebase/firebaseConfig";
import { Logger } from "../utils/logger";
import mailIcon from "../icons/mailIcon.png";
import LinkExpiring from "../components/SettingsTabs/LinkExpiring";
import ContactUs from "../components/SettingsTabs/ContactUs";
import Security from "../components/SettingsTabs/Security";
import Profile from "../components/SettingsTabs/Profile";
import ManageUsers from "../components/SettingsTabs/ManageUsers";
import ApproverNotificationPreferences from "../components/SettingsTabs/ApproverNotificationPreferences";
import Billing from "../components/SettingsTabs/Billing";
import { activeOrganizationAtom } from "../recoil/organization-atom";
import Integrations from "../components/SettingsTabs/Integrations";

const NewSettings = ({ activePath = "profile" }) => {
  const [organization, setActiveOrganization] = useRecoilState(activeOrganizationAtom)

  const [user, setUser] = useRecoilState(userAtom);
  const history = useNavigate();

  // const fileInputRef = React.createRef();

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(activePath);
  const [orgMembers, setOrgMembers] = useState([]);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const [addMemberIsLoading, addMemberSetIsLoading] = useState(false);
  const toast = useToast();
  const ownerId = user?._id;

  const [showUserManagement, setShowUserManagement] = useState(false);
  const [showAddUser, setShowShowAddUser] = useState(false);
  const [selectedOrgMember, setSelectedOrgMember] = useState(null);

    const organizationPersist = localStorage.getItem("recoil-persist");
    let isAdmin = false;
    let isOwner = false;

    if (organization && organizationPersist) {
      try {
        const parsedOrganization = JSON.parse(organizationPersist);
        const members = parsedOrganization.organizations || [];
        const myOrg = members.find((org) => org.id === organization.id);

        if (myOrg) {
          isOwner = myOrg.owner === user.id;
          isAdmin = myOrg.members.some(
            (member) => member.user === user.id && member.role === "admin"
          );
        }
      } catch (error) {
        console.error("Error parsing organization data:", error);
      }
    }
 

  const fetchMembers = async () => {
    try {
      const response = await axios.get(`/api/organizations/${organization?._id}/members`);
      setOrgMembers(response.data.members);
    } catch (error) {
      Logger.error("Error fetching organization members:", error);
    }
  };

  const fetchPendingInvitations = async () => {
    try {
      const response = await axios.get(
        `/api/organizations/pending-invitations-by-owner/${ownerId}`
      );
      setPendingInvitations(response.data);
    } catch (error) {
      Logger.error("Error fetching organization members:", error);
    }
  };

  const onLoadFetch = () => {
    fetchData();
    fetchMembers();
    fetchPendingInvitations();
  };

  const [changePassword, setChangePassword] = useState({
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [reminders, setReminders] = useState([
    { id: 1, days: 1, hours: 0 }, // Default for reminder 1
    { id: 2, days: 2, hours: 0 }, // Default for reminder 2
  ]);

  const [userProfile, setUserProfile] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    profilePicURL: user.profilePicURL,
  });

  const [imageData, setImageData] = useState([]);
  const [mediaIsChanged, setMediaIsChanged] = useState(false);

  const tabs = [
    { name: "profile", label: "Profile" },
    { name: "security", label: "Security" },
    {
      name: "approver-notification-preferences",
      label: "Approver Notification Preferences",
    },
    { name: "link-expiring", label: "Link Expiring" },
    // { name: "Manage Users", label: "Manage Users" },
    { name: "manage-users", label: "Manage Users" },
    { name: "billing", label: "Billing" },
    // { name: "Billing", label: "Billing" },
    { name: "contact-us", label: "Contact Us" },
    { name: "integrations", label: "Integrations" },
  ];

  // const handleProfileChange = (e) => {
  //   setUserProfile({
  //     ...userProfile,
  //     [e.target.id]: e.target.value,
  //   });
  // };

  // const addImageData = (newData) => {
  //   setImageData(newData);
  //   // setIsUploadModalOpen(false);
  //   setMediaIsChanged(true);
  // };

  // const triggerFileInput = () => {
  //   fileInputRef.current.click();
  // };

  const fetchData = async () => {
    if (user?._id) {
      // Assuming you have set up an endpoint like "/api/users/:id"
      try {
        const response = await axios.get(`/api/auth/user/${user._id}`);
        setUser(response.data);
      } catch (error) {
        toast({
          title: "An error occurred.",
          description: "Unable to retrieve user information.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handlePasswordUpdate = async () => {
    try {
      if (
        !changePassword.password ||
        !changePassword.newPassword ||
        !changePassword.confirmNewPassword
      ) {
        toast({
          title: "Please fill in all fields.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (changePassword.newPassword !== changePassword.confirmNewPassword) {
        toast({
          title: "Passwords do not match.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const auth = getAuth(firebaseApp);
      signInWithEmailAndPassword(
        auth,
        userProfile.email,
        changePassword.password
      )
        .then(async (userCredential) => {
          var fBUser = userCredential.user;

          await axios.patch(`/api/auth/user/${user._id}/password`, {
            firebaseUUID: fBUser.uid,
            newPassword: changePassword.newPassword,
          });

          toast({
            title: "Password changed successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });

          setChangePassword({
            password: "",
            newPassword: "",
            confirmNewPassword: "",
          });
        })
        .catch((error) => {
          let description = "Unable to change password.";
          if (error.code === "auth/wrong-password") {
            description = "Incorrect password";
          } else if (error.response && error.response.data) {
            description =
              error.response?.data?.message ??
              error.response?.data ??
              error.description;
          }

          toast({
            title: "An error occurred.",
            description: description,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    } catch (error) {
      Logger.error("Password update error:", error);
    }
  };

  const handlePasswordChange = (e) => {
    setChangePassword({
      ...changePassword,
      [e.target.id]: e.target.value,
    });
  };

  const handleSaveChanges = async () => {
    try {
      if (!validateEmailOrToast(userProfile.email, toast)) {
        return;
      }
      // Assuming you have set up a PUT endpoint like "/api/user/update/:id"
      let imageUri = await uploadToS3(imageData, user);
      const response = await axios.post(`/api/auth/user/update/${user._id}`, {
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.email,
        profilePicURL: mediaIsChanged ? imageUri : userProfile.profilePicURL,
      });
      setUser(response.data);
      toast({
        title: "Your changes have been saved.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      let description = "Unable to save changes.";

      if (
        error.response &&
        error.response.data &&
        error.response.data.msg.includes("Email already in use")
      ) {
        description = error.response.data.msg;
      }

      toast({
        title: "An error occurred.",
        description: description,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRemoveImage = () => {
    setImageData([]);
    setMediaIsChanged(true);
    setUserProfile({
      ...userProfile,
      profilePicURL: null,
    });
  };

  // const handleResendVerificationEmail = async () => {
  //   if (user.emailVerified) {
  //     toast({
  //       title: 'Email already verified.',
  //       status: 'error',
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //     return;
  //   }

  //   try {
  //     await axios.get(
  //       `api/users/resend-verification-email?firebaseAuthUUID=${user.firebaseAuthUUID}`
  //     );

  //     toast({
  //       title: 'Verification Email sent',
  //       status: 'success',
  //       toastId: 'resend-verification',
  //     });
  //   } catch (error) {
  //     toast({
  //       title: 'Unable to send verification email.',
  //       description: error.response.data.message,
  //       status: 'error',
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   }
  // };


  // const handleImageUpload = async (e) => {
  //   const files = Array.from(e.target.files); // Convert files to an array

  //   // Create an array of image data from the files
  //   const newImageData = await Promise.all(
  //     files.map(async (file) => {
  //       return new Promise((resolve) => {
  //         const reader = new FileReader();

  //         reader.onloadend = () => {
  //           resolve({
  //             name: file.name,
  //             data: reader.result,
  //             type: file.type.startsWith('image') ? 'image' : 'video',
  //           });
  //         };

  //         reader.readAsDataURL(file);
  //       });
  //     })
  //   );

  //   // Update the state with the new image data, overwriting any existing data
  //   addImageData(newImageData);
  // };

  // const reset = () => {
  //   setUserProfile({
  //     firstName: user.firstName,
  //     lastName: user.lastName,
  //     email: user.email,
  //     profilePicURL: user.profilePicURL,
  //   });

  //   setChangePassword({
  //     password: '',
  //     newPassword: '',
  //     confirmNewPassword: '',
  //   });

  //   setMediaIsChanged(false);
  // };

  useEffect(() => {
    fetchMembers();
    fetchData();
  }, []);

  const tabActions = {
    // profile: {
    //   label: "Save changes",
    //   handler: handleSaveChanges,
    // },
    // Add more tab actions here
  };

  useEffect(() => {
    setActiveTab(activePath);
  }, [window.location.pathname])

  return (
    <PageWrapper title="Technical workspace">
      <div className="mt-2.5 ml-3 flex justify-between h-9">
        <div className="text-xl font-semibold">Settings</div>
      </div>
      <div className="flex justify-start border-b mt-6 text-sm font-semibold">
        <div className="flex">
          {tabs.map((tab) => (
            <div
              key={tab.name}
              className={`px-4 py-2 cursor-pointer ${
                activeTab === tab.name
                  ? "text-blue-600 border-b-primary-600 border-b-2"
                  : "text-gray-400"
              }`}
              onClick={() => {
                // reset();
                setActiveTab(tab.name);
                history(`/${tab.name}`);
              }}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>
      <div className="mt-9 pl-4 overflow-y-auto max-h-[calc(100vh-200px)]">
        {activeTab === "profile" && (
          // (
          //   <div className="mt-2 flex flex-col">
          //     <div className="pb-4 border-b">
          //       <h4 className="text-gray-900 font-semibold text-lg">Profile</h4>
          //       <p className="text-gray-600 text-sm">
          //         Update your photo and personal details here
          //       </p>
          //     </div>
          //     <div className="flex flex-box mt-4">
          //       <div className="w-72">
          //         <h4 className="text-gray-700 font-semibold m-0">Your photo</h4>
          //         <p className="text-gray-600">
          //           This will be displayed on your profile
          //         </p>
          //       </div>
          //       <div className="flex flex-row gap-4">
          //         <div className="w-16 h-16 border rounded-full overflow-hidden">
          //           <img
          //             src={
          //               imageData[0]?.data ??
          //               userProfile.profilePicURL ??
          //               userIcon
          //             }
          //             alt="profile"
          //             className="w-full h-full"
          //           />
          //         </div>
          //         <div className="flex gap-4">
          //           <input
          //             type="file"
          //             ref={fileInputRef}
          //             multiple
          //             onChange={handleImageUpload}
          //             style={{ display: 'none' }}
          //             accept="image/*,video/*"
          //           />
          //           <button
          //             className="text-error-700 font-semibold"
          //             onClick={handleRemoveImage}
          //           >
          //             Delete
          //           </button>
          //           <button
          //             className="text-primary font-semibold"
          //             onClick={triggerFileInput}
          //           >
          //             Upload
          //           </button>
          //         </div>
          //       </div>
          //     </div>
          //     <div className="flex flex-box border-b py-5 items-center">
          //       <div className="w-72">
          //         <h4 className="text-gray-700 font-semibold m-0">First name</h4>
          //       </div>
          //       <div className="flex flex-row gap-4 max-w-[350px] w-full">
          //         <FormInput
          //           value={userProfile.firstName}
          //           onChange={handleProfileChange}
          //           id="firstName"
          //         />
          //       </div>
          //     </div>
          //     <div className="flex flex-box border-b py-5 items-center">
          //       <div className="w-72">
          //         <h4 className="text-gray-700 font-semibold m-0">Last name</h4>
          //       </div>
          //       <div className="flex flex-row gap-4 max-w-[350px] w-full">
          //         <FormInput
          //           value={userProfile.lastName}
          //           onChange={handleProfileChange}
          //           id="firstName"
          //         />
          //       </div>
          //     </div>
          //     <div className="flex flex-box border-b py-5 items-center">
          //       <div className="w-72">
          //         <h4 className="text-gray-700 font-semibold m-0">
          //           Email address
          //         </h4>
          //       </div>
          //       <div className="flex items-center max-w-[350px] w-full relative">
          //         <img
          //           src={mailIcon}
          //           alt="Mail Icon"
          //           className="absolute left-3 top-7 transform -translate-y-1/2"
          //         />
          //         <FormInput
          //           value={userProfile.email}
          //           onChange={handleProfileChange}
          //           id="email"
          //           className="pl-10 border rounded-lg py-2 px-3 w-full"
          //         />

          //         {/* <mailIcon /> {user.email} */}
          //       </div>
          //       {!user.emailVerified ? (
          //         <p
          //           className="px-3 cursor-pointer text-xs text-error-500"
          //           onClick={handleResendVerificationEmail}
          //         >
          //           Verify email
          //         </p>
          //       ) : null}
          //     </div>
          //   </div>
          // )
          <Profile />
        )}
        {activeTab === "security" && (
          // (
          //   <div className="flex flex-col">
          //     <div className="pb-4 border-b">
          //       <h4 className="text-gray-900 font-semibold text-lg">Profile</h4>
          //       <p className="text-gray-600 text-sm">
          //         Update your photo and personal details here
          //       </p>
          //     </div>
          //     <div className="flex flex-box border-b py-5 items-center">
          //       <div className="w-72">
          //         <h4 className="text-gray-700 font-semibold m-0">
          //           Old Password
          //         </h4>
          //       </div>
          //       <div className="flex flex-row gap-4 max-w-[350px] w-full">
          //         <FormInput
          //           value={changePassword.password}
          //           type={"password"}
          //           placeholder={"Old password"}
          //           onChange={handlePasswordChange}
          //           id="password"
          //         />
          //       </div>
          //     </div>
          //     <div className="flex flex-box border-b py-5 items-center">
          //       <div className="w-72">
          //         <h4 className="text-gray-700 font-semibold m-0">
          //           New Password
          //         </h4>
          //       </div>
          //       <div className="flex flex-row gap-4 max-w-[350px] w-full">
          //         <FormInput
          //           value={changePassword.newPassword}
          //           type={'password'}
          //           placeholder={'New password'}
          //           onChange={handlePasswordChange}
          //           id="newPassword"
          //         />
          //       </div>
          //     </div>
          //     <div className="flex flex-box border-b py-5 items-center">
          //       <div className="w-72">
          //         <h4 className="text-gray-700 font-semibold m-0">
          //           Confirm New Password
          //         </h4>
          //       </div>
          //       <div className="flex items-center max-w-[350px] w-full">
          //         <FormInput
          //           value={changePassword.confirmNewPassword}
          //           type={'password'}
          //           placeholder={'Confirm new password'}
          //           onChange={handlePasswordChange}
          //           id="confirmNewPassword"
          //         />
          //       </div>
          //     </div>
          //   </div>
          // )
          <Security
            handlePasswordChange={handlePasswordChange}
            changePassword={changePassword}
            setChangePassword={setChangePassword}
            handlePasswordUpdate={handlePasswordUpdate}
            loading={loading}
            setLoading={setLoading}
          />
        )}

        {activeTab === "approver-notification-preferences" && (
          <ApproverNotificationPreferences
            user={user}
            fetchData={fetchData}
            reminders={reminders}
            setReminders={setReminders}
            isAdmin={isAdmin}
            isOwner={isOwner}
          />
        )}
        {activeTab === "link-expiring" && <LinkExpiring />}
        {activeTab === "manage-users" && (
          <ManageUsers
            user={user}
            isAdmin={isAdmin}
            isOwner={isOwner}
            pendingInvitations={pendingInvitations}
            orgMembers={orgMembers}
            setShowShowAddUser={setShowShowAddUser}
            addMemberSetIsLoading={addMemberSetIsLoading}
            onLoadFetch={onLoadFetch}
            setSelectedOrgMember={setSelectedOrgMember}
            addMemberIsLoading={addMemberIsLoading}
            setShowUserManagement={setShowUserManagement}
            selectedOrgMember={selectedOrgMember}
            refreshList={fetchMembers}
          />
        )}
        {activeTab === "billing" && <Billing user={user} />}
        {activeTab === "contact-us" && <ContactUs />}
        {activeTab === "integrations" && (
          <Integrations user={user} setView={setActiveTab} />
        )}
        {/* {tabActions[activeTab] && (
          <div className="flex justify-end mt-6">
            <button
              className="bg-primary-600 text-white py-2.5 px-5 rounded-md"
              onClick={tabActions[activeTab]?.handler}
            >
              {tabActions[activeTab]?.label}
            </button>
          </div>
        )}
        {/* <div className="mt-6 flex justify-between items-center">

        <button
            className="text-primary-600 bg-white outline py-2.5 px-5 rounded-md"
          >
            Logout
          </button>
        
          <button
            className="bg-primary-600 text-white py-2.5 px-5 rounded-md"
            onClick={
              activeTab === 'profile' ? handleSaveChanges : handlePasswordUpdate
            }
          >
            {activeTab === 'profile' ? 'Save changes' : 'Update password'}
          </button>
        </div> */}
      </div>
    </PageWrapper>
  );
};

export default NewSettings;
