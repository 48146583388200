import React, { useEffect, useState } from 'react';

const CheckboxWithTick = ({
  partial,
  value,
  onSelect,
  onCheck,
  allSelected,
  isSelected,
  forced,
  description,
  disabled,
}) => {
  const [isChecked, setIsChecked] = useState(!!allSelected || isSelected);

  const handleChange = () => {
    if (onSelect) {
      onSelect(value);
    }
    if (onCheck) {
      onCheck(!isChecked);
    }
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    setIsChecked(!!allSelected || isSelected);
  }, [allSelected, isSelected]);

  return (
    <label className="flex items-center cursor-pointer">
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
        className="hidden"
        disabled={disabled}
      />
      <span
        className={`text-sm font-semibold transition-colors ${
          disabled ? 'text-gray-400' :
          (isChecked || allSelected || partial)
            ? 'text-primary border-primary bg-primary-50'
            : 'text-gray-700 bg-white'
        } border rounded-md w-5 h-5 flex items-center justify-center`}
      >
        {isChecked && !forced ? '✓' : partial || forced ? '–' : ''}
      </span>
      <span className="ml-2 text-sm">{description}</span>
    </label>
  );
};

export default CheckboxWithTick;

