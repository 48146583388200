import axios from "axios";
import React, { useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { Logger } from "../../utils/logger";
import Table from "../Table/Table";
import AddUserModal from "../Modal/AddUserModel";
import { useToast } from "@chakra-ui/react";
import { validateEmailOrToast } from "../../utils/validateUtils";
import UserProfileModal from "../Modal/UserProfileModal";
import { useRecoilState } from "recoil";
import { activeOrganizationAtom } from "../../recoil/organization-atom";

const ManageUsers = ({
  user,
  pendingInvitations,
  setShowUserManagement,
  orgMembers,
  setShowShowAddUser,
  addMemberSetIsLoading,
  addMemberIsLoading,
  onLoadFetch,
  setSelectedOrgMember,
  selectedOrgMember,
  refreshList,
  isAdmin,
  isOwner
}) => {
  const [organization, setActiveOrganization] = useRecoilState(activeOrganizationAtom)
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [addMemberFirstName, addMemberSetFirstName] = useState("");
  const [addMemberLastName, addMemberSetLastName] = useState("");
  const [addMemberEmail, addMemberSetEmail] = useState("");
  const [addMemberRole, addMemberSetRole] = useState("team");
  const [showUserProf, setShowUserProf] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const toast = useToast();

  const deleteInvitation = async (invitationId) => {
    try {
      await axios.delete(`/api/organizations/invitation/${invitationId}`);
      // Remove the invitation from the state to update the UI
      addMemberSetIsLoading(false); // Stop loading animation
      setShowUserProf(false); // Close the profile/modal view
      onLoadFetch(); // Refresh the list of members
    } catch (error) {
      Logger.error("Error deleting invitation:", error);
    }
  };

  const updateUserProf = (member) => {
    setSelectedOrgMember(member);
    setShowUserProf(true);
  };

  const handleSendInvite = async () => {
    addMemberSetIsLoading(true); // Start loading animation
    if (!validateEmailOrToast(addMemberEmail, toast)) {
      addMemberSetIsLoading(false); // Stop loading animation
      return;
    }
    try {
      const response = await axios.post("/api/organizations/invite", {
        email: addMemberEmail,
        role: addMemberRole,
        userID: user?._id, // This should be dynamically set based on the logged-in user
      });
      toast({
        title: "Invitation Sent",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await refreshList();
    } catch (error) {
      toast({
        title: "Error",
        description: error.response.data.message || "Failed to send invitation",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    addMemberSetIsLoading(false); // Stop loading animation
    setIsAddingUser(false);
    onLoadFetch();
  };

  const handleSaveMember = async () => {
    // Add loading logic here if needed
    addMemberSetIsLoading(true); // Start loading animation
    if (!validateEmailOrToast(selectedOrgMember.email, toast)) {
      addMemberSetIsLoading(false); // Stop loading animation
      return;
    }
    try {
      const response = await axios.post(`/api/organizations/${organization._id}/updateMember`, {
        userId: user?._id, // This should be dynamically set based on the logged-in user
        firstName: selectedOrgMember.firstName,
        lastName: selectedOrgMember.lastName,
        email: selectedOrgMember.email,
        role: addMemberRole,
      });
      toast({
        title: "User updated",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await refreshList();
    } catch (error) {
      toast({
        title: "Error",
        description: error.response.data.message || "Failed to send invitation",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    // Add loading logic here if needed
    addMemberSetIsLoading(false); // Stop loading animation
    setShowUserProf(false);
    onLoadFetch();
  };

  const handleRemoveMember = async () => {
    addMemberSetIsLoading(true); // Start loading animation for UI feedback
    if (!validateEmailOrToast(selectedOrgMember.email, toast)) {
      setShowUserProf(false); // Close the profile/modal view
      return;
    }
    try {
      const response = await axios.post("/api/organizations/removeMember", {
        userId: user?._id, // Dynamically set based on the logged-in user
        memberEmail: selectedOrgMember.email, // Email of the member to remove
      });
      toast({
        title: "Member removed",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to remove member",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    addMemberSetIsLoading(false); // Stop loading animation
    setShowUserProf(false); // Close the profile/modal view
    onLoadFetch(); // Refresh the list of members
  };

  // const handleFirstNameChange = (e) => {
  //   setSelectedOrgMember((prev) => ({
  //     ...prev,
  //     firstName: e.target.value,
  //   }));
  // };

  // const handleLastNameChange = (e) => {
  //   setSelectedOrgMember((prev) => ({
  //     ...prev,
  //     lastName: e.target.value,
  //   }));
  // };

  // const handleRoleChange = (e) => {
  //   setSelectedOrgMember((prev) => ({
  //     ...prev,
  //     role: e.target.value,
  //   }));
  // };

  const handleAddNewUser = () => {
    addMemberSetFirstName("");
    addMemberSetLastName("");
    addMemberSetEmail("");
    addMemberSetRole("team");
    setIsEdit(false);
    setIsAddingUser(true);
  };

  const handleEditUser = (member) => {
    updateUserProf(member);
    addMemberSetFirstName(member.firstName);
    addMemberSetLastName(member.lastName);
    addMemberSetEmail(member.email);
    addMemberSetRole(member.role);
    setIsEdit(true);
    setIsAddingUser(true);
  };

  return (
    <div className="flex flex-col">
      {!isAddingUser ? (
        <>
          <div className="flex justify-between pb-4 border-b">
            <div>
              <h4 className="text-gray-900 font-semibold text-lg">
                Manage Users
              </h4>
              <p className="text-gray-600 text-sm">
                Add, remove, update user permissions.
              </p>
            </div>
            {(isAdmin || isOwner) ? (
            <div>
              <button
                className="w-40 bg-primary-600 hover:bg-blue-400 text-white font-semibold py-2 px-4 mx-2 rounded-lg"
                onClick={handleAddNewUser}
              >
                Add New User
              </button>
            </div>
            ): null}
          </div>
          <div className="mt-6">
            <Table
              cols={10}
              fields={[
                { key: "name", value: "Name", span: 6 },
                { key: "role", value: "Role", span: 3 },
              ]}
              data={orgMembers.map((member) => {
                return {
                  ...member,
                  id: member._id,
                  name: {
                    children: (
                      <div className="flex">
                        <div className="flex items-center justify-center bg-primary text-white font-bold rounded-full w-10 h-10">
                          {`${member.firstName
                            .charAt(0)
                            .toUpperCase()}${member.lastName
                            .charAt(0)
                            .toUpperCase()}`}
                        </div>
                        <div className="ml-4">
                          <p className="font-semibold">{`${member.firstName} ${member.lastName}`} {member.isInvite ? <sup className="border px-1 rounded bg-warning-50 border-warning-200 text-warning-500">Pending Invitation</sup>: null}</p>
                          <p className="text-sm text-gray-500">
                            {member?.email}
                          </p>
                        </div>
                      </div>
                    ),
                    span: 6,
                  },
                  role: {
                    children: <p>{member?.role}</p>,
                    span: 3,
                  },
                  ...((isAdmin || isOwner) ?
                    {
                      handleDelete: () => {
                         handleRemoveMember();
                         setSelectedOrgMember(member)
                       },

                       ...(!member.isInvite ? {handleEdit: () => {
                         handleEditUser(member);
                       }}: {}),
                    } : {}),
                };
              })}
              tableType={true}
            />
          </div>
        </>
      ) : (
        <AddUserModal
          addMemberEmail={addMemberEmail}
          addMemberFirstName={addMemberFirstName}
          addMemberIsLoading={addMemberIsLoading}
          addMemberLastName={addMemberLastName}
          addMemberRole={addMemberRole}
          addMemberSetEmail={addMemberSetEmail}
          addMemberSetFirstName={addMemberSetFirstName}
          addMemberSetLastName={addMemberSetLastName}
          addMemberSetRole={addMemberSetRole}
          handleSendInvite={handleSendInvite}
          setIsAddingUser={setIsAddingUser}
          isEdit={isEdit}
          setShowUserManagement={setShowUserManagement}
          // handleFirstNameChange={handleFirstNameChange}
          // handleLastNameChange={handleLastNameChange}
          // handleRoleChange={handleRoleChange}
          handleSaveMember={handleSaveMember}
        />
      )}

      {/* {showUserProf && (
        <UserProfileModal
          addMemberIsLoading={addMemberIsLoading}
          handleFirstNameChange={handleFirstNameChange}
          handleLastNameChange={handleLastNameChange}
          handleRemoveMember={handleRemoveMember}
          handleRoleChange={handleRoleChange}
          handleSaveMember={handleSaveMember}
          selectedOrgMember={selectedOrgMember}
          setShowUserManagement={setShowUserManagement}
          setShowUserProf={setShowUserProf}
        />
      )} */}
    </div>
  );
};

export default ManageUsers;
