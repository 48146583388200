import { useToast } from "@chakra-ui/react";
import axios from "axios";

const ApproverNotificationPreferences = ({
  user,
  fetchData,
  reminders,
  setReminders,
  isAdmin,
  isOwner,
}) => {
  const toast = useToast();

  const handleReminderCountChange = (e) => {
    const count = parseInt(e.target.value);
    const newReminders = [...reminders];

    if (count < newReminders.length) {
      setReminders(newReminders.slice(0, count));
    } else {
      for (let i = newReminders.length; i < count; i++) {
        newReminders.push({ id: i + 1, days: 0, hours: 0 });
      }
      setReminders(newReminders);
    }
  };

  const handleDayChange = (id, days) => {
    setReminders(
      reminders.map((reminder) =>
        reminder.id === id ? { ...reminder, days: days } : reminder
      )
    );
  };

  const handleHourChange = (id, hours) => {
    setReminders(
      reminders.map((reminder) =>
        reminder.id === id ? { ...reminder, hours: hours } : reminder
      )
    );
  };

  const checkRemindersValidity = () => {
    let lastTotalHours = 0;

    for (const reminder of reminders) {
      if (reminder.days === 0 && reminder.hours === 0) {
        toast({
          title: "Invalid Reminder Setting",
          description: "No reminder can have both 0 days and 0 hours.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return false;
      }

      const totalHours = reminder.days * 24 + reminder.hours;
      if (totalHours <= lastTotalHours) {
        toast({
          title: "Invalid Reminder Sequence",
          description:
            "Each subsequent reminder must be set for a later time than the previous one.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return false;
      }
      lastTotalHours = totalHours;
    }

    return true;
  };

  const handleSavePreferences = async () => {
    if (checkRemindersValidity()) {
      try {
        const response = await axios.post(`/api/auth/update-user-preferences`, {
          _id: user._id,
          notificationPreferences: reminders,
        });
        toast({
          title: "Your changes have been saved.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        await fetchData();
      } catch (error) {
        toast({
          title: "An error occurred.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };
  return (
    <div className="mt-2 bg-white">
      <div className="border-b">
        <h2 className="text-lg font-semibold mb-2">
          Approver Notification Preferences
        </h2>
        <p className="text-gray-600 mb-6">
          {" "}
          Customize how many and how often approvers will receive automated
          email notifications to review content.
        </p>
      </div>

      <div className="border-b">
        <div className="mt-4 flex mb-4">
          <label
            htmlFor="reminder-count"
            className="block text-gray-700 text-sm font-bold w-5/6"
          >
            # of approver reminders
          </label>
          <select
            id="reminder-count"
            onChange={handleReminderCountChange}
            defaultValue={reminders?.length || 2}
            className="shadow border mr-[40%] rounded-lg w-40 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            {/* Options for reminder count */}
            {[...Array(5).keys()].map((number) => (
              <option key={number} value={number + 1}>
                {number + 1}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        {reminders.map((reminder, index) => (
          <div
            key={reminder.id}
            className="flex justify-between items-center mt-4 mb-4 border-b"
          >
            <span className="text-gray-700 text-sm font-bold">
              Reminder {index + 1}
            </span>
            <div className="flex gap-2 mb-4">
              <select
                value={reminder.days}
                onChange={(e) =>
                  handleDayChange(reminder.id, parseInt(e.target.value))
                }
                className="border rounded-lg py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value={0}>0 days</option>
                {/* Options for days */}
                {[...Array(31).keys()].slice(1).map((day) => (
                  <option key={day} value={day}>
                    {day} days
                  </option>
                ))}
              </select>
              <select
                value={reminder.hours}
                onChange={(e) =>
                  handleHourChange(reminder.id, parseInt(e.target.value))
                }
                className="border rounded-lg mr-96 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                {/* Options for hours */}
                {[...Array(24).keys()].map((hour) => (
                  <option key={hour} value={hour}>
                    {hour} hours
                  </option>
                ))}
              </select>
            </div>
          </div>
        ))}
      </div>
      {/* Action Buttons */}
      {(isAdmin || isOwner) ? (
        <div className="flex justify-end mt-6">
          <button
            className="bg-primary-600 text-white w-1/6 py-2.5 px-5 rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={() => handleSavePreferences()}
          >
            Save
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default ApproverNotificationPreferences;
