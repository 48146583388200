import React from 'react';
import { useToast } from '@chakra-ui/react';
import uploadToS3 from '../../utils/uploadToS3';
import userIcon from '../../icons/manage-user-icon.png';
import mailIcon from '../../icons/mailIcon.png';
import FormInput from '../Form/FormInput';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../recoil/userAtoms';
import { useState } from 'react';
import { validateEmailOrToast } from '../../utils/validateUtils';
import axios from 'axios';

const Profile = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const [userProfile, setUserProfile] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    profilePicURL: user.profilePicURL,
  });

  const fileInputRef = React.createRef();
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const toast = useToast();
  const [imageData, setImageData] = useState([]);
  const [mediaIsChanged, setMediaIsChanged] = useState(false);

  const addImageData = (newData) => {
    setImageData(newData);
    // setIsUploadModalOpen(false);
    setMediaIsChanged(true);
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files); // Convert files to an array

    // Create an array of image data from the files
    const newImageData = await Promise.all(
      files.map(async (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve({
              name: file.name,
              data: reader.result,
              type: file.type.startsWith('image') ? 'image' : 'video',
            });
          };

          reader.readAsDataURL(file);
        });
      })
    );

    // Update the state with the new image data, overwriting any existing data
    addImageData(newImageData);
  };

  const handleProfileChange = (e) => {
    setUserProfile({
      ...userProfile,
      [e.target.id]: e.target.value,
    });
  };

  const handleSaveChanges = async () => {
    try {
      if (!validateEmailOrToast(userProfile.email, toast)) {
        return;
      }
      // Assuming you have set up a PUT endpoint like "/api/user/update/:id"
      let imageUri = await uploadToS3(imageData, user);
      const response = await axios.post(`/api/auth/user/update/${user._id}`, {
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.email,
        profilePicURL: mediaIsChanged ? imageUri : userProfile.profilePicURL,
      });
      setUser(response.data);
      toast({
        title: 'Your changes have been saved.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      let description = 'Unable to save changes.';

      if (
        error.response &&
        error.response.data &&
        error.response.data.msg.includes('Email already in use')
      ) {
        description = error.response.data.msg;
      }

      toast({
        title: 'An error occurred.',
        description: description,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRemoveImage = () => {
    setImageData([]);
    setMediaIsChanged(true);
    setUserProfile({
      ...userProfile,
      profilePicURL: null,
    });
  };

  const handleResendVerificationEmail = async () => {
    if (user.emailVerified) {
      toast({
        title: 'Email already verified.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.get(
        `api/users/resend-verification-email?firebaseAuthUUID=${user.firebaseAuthUUID}`
      );

      toast({
        title: 'Verification Email sent',
        status: 'success',
        toastId: 'resend-verification',
      });
    } catch (error) {
      toast({
        title: 'Unable to send verification email.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <div className="mt-2 flex flex-col">
        <div className="pb-4 border-b">
          <h4 className="text-gray-900 font-semibold text-lg">Profile</h4>
          <p className="text-gray-600 text-sm">
            Update your photo and personal details here
          </p>
        </div>
        <div className="flex flex-box mt-4">
          <div className="w-72">
            <h4 className="text-gray-700 font-semibold m-0">Your photo</h4>
            <p className="text-gray-600">
              This will be displayed on your profile
            </p>
          </div>
          <div className="flex flex-row gap-4">
            <div className="w-16 h-16 border rounded-full overflow-hidden">
              <img
                src={
                  imageData[0]?.data ?? userProfile.profilePicURL ?? userIcon
                }
                alt="profile"
                className="w-full h-full"
              />
            </div>
            <div className="flex gap-4">
              <input
                type="file"
                ref={fileInputRef}
                multiple
                onChange={handleImageUpload}
                style={{ display: 'none' }}
                accept="image/*,video/*"
              />
              <button
                className="text-error-700 font-semibold"
                onClick={handleRemoveImage}
              >
                Delete
              </button>
              <button
                className="text-primary font-semibold"
                onClick={triggerFileInput}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-box border-b py-5 items-center">
          <div className="w-72">
            <h4 className="text-gray-700 font-semibold m-0">First name</h4>
          </div>
          <div className="flex flex-row gap-4 max-w-[350px] w-full">
            <FormInput
              value={userProfile.firstName}
              onChange={handleProfileChange}
              id="firstName"
            />
          </div>
        </div>
        <div className="flex flex-box border-b py-5 items-center">
          <div className="w-72">
            <h4 className="text-gray-700 font-semibold m-0">Last name</h4>
          </div>
          <div className="flex flex-row gap-4 max-w-[350px] w-full">
            <FormInput
              value={userProfile.lastName}
              onChange={handleProfileChange}
              id="firstName"
            />
          </div>
        </div>
        <div className="flex flex-box border-b py-5 items-center">
          <div className="w-72">
            <h4 className="text-gray-700 font-semibold m-0">Email address</h4>
          </div>
          <div className="flex items-center max-w-[350px] w-full relative">
            <img
              src={mailIcon}
              alt="Mail Icon"
              className="absolute left-3 top-7 transform -translate-y-1/2"
            />
            <FormInput
              value={userProfile.email}
              onChange={handleProfileChange}
              id="email"
              className="pl-10 border rounded-lg py-2 px-3 w-full"
            />

            {/* <mailIcon /> {user.email} */}
          </div>
          {!user.emailVerified ? (
            <p
              className="px-3 cursor-pointer text-xs text-error-500"
              onClick={handleResendVerificationEmail}
            >
              Verify email
            </p>
          ) : null}
        </div>
      </div>
      <div className="flex justify-end mt-6">
        <button
          className="bg-primary-600 text-white py-2.5 px-5 rounded-md"
          onClick={handleSaveChanges}
        >
          Save changes
        </button>
      </div>
    </>
  );
};

export default Profile;
