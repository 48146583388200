import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import SignUp from './pages/SignUp';
import Onboard from './pages/Onboard';
import ForgotPassword from './pages/ForgotPassword';
import ApprovalRequestTimeline from './pages/ApprovalRequestTimeline';
import './index.css';
import axios from 'axios';
import CreateApprovalRequest from './pages/CreateApprovalRequest';
import Workspaces from './pages/Workspaces';
import ReviewApprovalRequest from './pages/ReviewApprovalRequest';
import EditApprovalRequest from './pages/EditApprovalRequest';
import Notifications from './pages/Notifications';
import Settings from './pages/Settings';
import CreateApprovalRequestEmail from './pages/CreateApprovalRequestEmail';
import EditApprovalRequestEmail from './pages/EditApprovalRequestEmail';
import PlanSelect from './pages/PlanSelect';
import Privacy from './pages/Privacy';
import VideoPlayer from './components/VideoPlayer';
import EarlyAccess from './pages/EarlyAccess';
import { userAtom } from './recoil/userAtoms';
import { useRecoilValue } from 'recoil';
import PNF from './pages/404';
import { GoogleOAuthProvider } from '@react-oauth/google';
import VerifyEmail from './pages/VerifyEmail';
import Login from './pages/Login';
import Verify2FaAndLogin from './pages/Verify2FaAndLogin';
import Approvals from './pages/ApprovalR';
import NewSettings from './pages/NewSettings';
import CreateApprovalR from './pages/CreateApprovalR';
import ApprovalRequestTimeL from './pages/ApprovalRequestTimeL';
import EditApprovalR from './pages/EditApprovalR';
import ReviewApprovalR from './pages/ReviewApprovalR';
import NotificationList from './pages/Notificate';
import Reset2FA from './pages/Reset2FA';
import ExcelViewer from './components/ExcelViewer';
axios.defaults.baseURL = process.env.REACT_APP_DEV_API_BASE_URL;

function App() {
  const user = useRecoilValue(userAtom);
  const isAuthenticated = user && Object.keys(user).length !== 0; // Update this based on how you're tracking authenticated users

  return (
    <Router>
      <GoogleOAuthProvider clientId="701177215582-m10tn0hun38pudm6aqdrp4asei35pm1q.apps.googleusercontent.com">
        <div className="App">
          <Routes>
            <Route path="/" element={<EarlyAccess />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/login" element={<Login />} />
            {
              process.env.NODE_ENV === 'development' &&
                <Route
                  path="/test"
                  element={

                    <ExcelViewer
  
                      />
                  }
                />
              
            }
            <Route
              path="/work/:timelineID"
              element={<ApprovalRequestTimeL />}
            />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/accept-invite" element={<SignUp />} />
            <Route path="/select-plan" element={<PlanSelect />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/2fa-login" element={<Verify2FaAndLogin />} />
            <Route path="/reset-2fa" element={<Reset2FA />} />
            <Route
              path="/workspaces"
              element={
                isAuthenticated ? <Workspaces /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/profile"
              element={
                isAuthenticated ? <NewSettings /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/security"
              element={
                isAuthenticated ? (
                  <NewSettings activePath="security" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/approver-notification-preferences"
              element={
                isAuthenticated ? (
                  <NewSettings activePath="approver-notification-preferences" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/link-expiring"
              element={
                isAuthenticated ? (
                  <NewSettings activePath="link-expiring" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/billing"
              element={
                isAuthenticated ? (
                  <NewSettings activePath="billing" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/manage-users"
              element={
                isAuthenticated ? (
                  <NewSettings activePath="manage-users" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/contact-us"
              element={
                isAuthenticated ? (
                  <NewSettings activePath="contact-us" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/integrations"
              element={
                isAuthenticated ? (
                  <NewSettings activePath="integrations" />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/onboard"
              element={isAuthenticated ? <Onboard /> : <Navigate to="/login" />}
            />
            <Route
              path="/settings"
              element={
                isAuthenticated ? <Settings /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/notifications"
              element={
                isAuthenticated ? (
                  <NotificationList />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/create-request"
              element={
                isAuthenticated ? <CreateApprovalR /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/create-request-email"
              element={
                isAuthenticated ? (
                  <CreateApprovalRequestEmail />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/workspace/:workspaceID"
              element={
                isAuthenticated ? <Approvals /> : <Navigate to="/login" />
              }
            />
            <Route path="/review" element={<ReviewApprovalR />} />
            <Route
              path="/edits/:approvalReqID"
              element={
                isAuthenticated ? (
                  <ReviewApprovalRequest />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/edit/:approvalReqID"
              element={
                isAuthenticated ? <EditApprovalR /> : <Navigate to="/login" />
              }
            />
            <Route
              path="/edit-email/:approvalReqID"
              element={
                isAuthenticated ? (
                  <EditApprovalRequestEmail />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/approvalRequest/:timelineID"
              element={
                isAuthenticated ? (
                  <ApprovalRequestTimeL />
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/verify-email/:firebaseAuthUUID"
              element={<VerifyEmail />}
            />
            <Route path="*" element={<PNF />} />
          </Routes>
        </div>
      </GoogleOAuthProvider>
    </Router>
  );
}

export default App;
