import { FaXmark } from 'react-icons/fa6';
import Attachment from '../SVGs/Attachment';
import { truncateString } from '../../utils/stringUtils';

const DocumentAttachment = ({ attachment, pos = 1, allowView = true }) => {
  return (
    <div className="flex items-center gap-2 p-3 border rounded">
      <div className="flex items-center gap-2 px-1 rounded justify-between w-full">
        <a
          href={attachment.url}
          target="_blank"
          className="flex gap-1 text-nowrap justify-center items-center"
        >
          <Attachment size="15" />{' '}
          <span className="no-wrap">{truncateString(attachment.name, 30) ?? `Attachment ${pos}`}</span>
        </a>
        {allowView && <a target='_blank' href={attachment.url}>
            <span className="cursor-pointer underline">
              View
          </span>
          </a>
        }
      </div>
    </div>
  );
};

export default DocumentAttachment;
