// recoil/notificationsAtom.js
import { atom, selector } from "recoil";
import axios from "axios";

// Atom to store the unread notification status
export const unreadNotificationsState = atom({
  key: "unreadNotificationsState",
  default: false,
});

// Selector to define and share the fetch function
export const unreadNotificationFetcher = selector({
  key: "unreadNotificationFetcher",
  get: () => async (firebaseAuthUUID) => {
    if (!firebaseAuthUUID) return false;
    try {
      const response = await axios.get(`/api/notifications/unread-count/`, {
        params: { firebaseAuthUUID },
      });
      const { notificationCount } = response.data;
      return notificationCount > 0;
    } catch (error) {
      return false;
    }
  },
});
