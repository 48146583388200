import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import settingsIcon from '../../icons/settings-icon.png';
import logOutIconn from '../../icons/logOutIconn.png';
import userIcon from '../../icons/manage-user-icon.png';

const ProfileModal = ({ user, onLogout }) => {
  const urlPath = window.location.pathname.split('/')[1];
  const paths = ['profile', 'security', 'link-expiring', 'contact-us'];

  const navigate = useNavigate();
  return (
    <>
      <div className="absolute top-[7%] right-8  bg-white shadow-lg border rounded-lg z-50">
        <div className="absolute -top-1 right-8 flex justify-center">
          <div className="relative">
            <div className="absolute inset-x-0 mx-auto w-3 h-3 bg-white rotate-45 border-t border-l border-gray-200"></div>
          </div>
        </div>
        <div className="p-4 flex items-center gap-3 border-b">
          <img
            src={user.profilePicURL ?? userIcon}
            alt="Profile"
            className="w-10 h-10 rounded-full"
          />
          <div>
            <p className="text-sm font-semibold text-gray-800">
              {user.fullName}
            </p>
            <p className="text-gray-500 text-sm font-normal leading-5">
              {user.email}
            </p>
          </div>
        </div>
        <div className="flex flex-col">
          <button
            className="w-full flex items-center gap-3 px-4 py-3 text-sm font-medium text-gray-700 hover:bg-gray-50"
            onClick={() => {
              if (!paths.includes(urlPath)) {
                navigate('/profile');
              }
            }}
          >
            <img
              src={settingsIcon}
              alt="settings icon"
              className="w-5 h-5 object-contain"
            />
            Settings
          </button>
          <button
            className="w-full flex items-center gap-3 px-4 py-3 text-sm text-red-600 font-medium hover:bg-gray-50"
            onClick={onLogout}
          >
            <img
              src={logOutIconn}
              alt="Log out"
              className="w-5 h-5 object-contain"
            />
            Log out
          </button>
        </div>
      </div>
    </>
  );
};

export default ProfileModal;
