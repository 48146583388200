import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { Logger } from "../../utils/logger";

const Billing = ({ user }) => {
  const toast = useToast();

  const handleOpenStripePortal = async () => {
    try {
      const { data } = await axios.post(
        "/api/auth/create-stripe-portal-session",
        { userId: user._id }
      );
      window.location.href = data.url;
    } catch (error) {
      Logger.error("Error opening Stripe portal:", error);
      toast({
        title: "Error",
        description: "Error opening Stripe portal",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  return (
    <div className="flex flex-col">
      <div className="pb-4 border-b">
        <h4 className="text-gray-900 font-semibold text-lg">Billing</h4>
        <p className="text-gray-600 text-sm">Update your billing preferences</p>
      </div>
      <button
        className="w-72 mt-10 text-md px-4 py-2 font-semibold text-blue-700 border border-blue-200 hover:bg-blue-300 bg-blue-50 rounded-lg  active:bg-blue-800"
        type="button"
        onClick={() => handleOpenStripePortal()}
      >
        Click here to view your billing
      </button>
    </div>
  );
};

export default Billing;
