import { useState } from 'react';
import ModalContent from './ModalContent';
import FormInput from '../Form/FormInput';
import useError from '../../hooks/useError';

const AddApproverModal = ({
  closeModal,
  isOpen = true,
  level = 1,
  addApprover,
  handleLevelSettings,
}) => {
  const { errors, addError, clearErrors, removeError } = useError([
    'email',
    'firstName',
    'lastName',
  ]);

  const [approverData, setApproverData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    level,
  });

  const validateApproverDetails = (data) => {
    clearErrors();

    if (!checkAllFieldsCompleted()) {
      data.firstName === '' && addError('firstName', 'First name is required');
      data.lastName === '' && addError('lastName', 'Last name is required');
      data.email === '' && addError('email', 'Email is required');

      return true;
    }

    const email = data.email;
    const emailValid = /\S+@\S+\.\S+/.test(email);

    if (!emailValid) {
      addError('email', 'Please enter a valid email address');
      return true;
    }
  };

  const handleAddApprover = () => {
    const fieldsCompleted = checkAllFieldsCompleted();

    if (fieldsCompleted) {
      const error = validateApproverDetails(approverData);
      if (error) return;

      addApprover(approverData);
      handleLevelSettings();
    
      setApproverData({
        firstName: '',
        lastName: '',
        email: '',
        level,
      });

      closeModal();
    }
  };

  const checkAllFieldsCompleted = () => {
    if (approverData.firstName && approverData.lastName && approverData.email) {
      return true;
    }
    return false;
  };

  const handleApproverChange = (e) => {
    removeError(e.target.name);

    setApproverData({
      ...approverData,
      [e.target.name]: e.target.value,
    });
  };

  const closeApproverModal = () => {
    setApproverData({
      firstName: '',
      lastName: '',
      email: '',
      level,
    });
    clearErrors(['firstName', 'lastName', 'email']);

    closeModal();
  };

  return (
    <ModalContent
      title={`Add Level ${level} Approver`}
      className={'max-w-[480px]'}
      closeModal={closeApproverModal}
      openModal={isOpen}
    >
      <div className="flex flex-col items-center gap-4 text-sm">
        <>
          <p>
            Members of your team will get access to the workspace, be able to
            view projects, and can be assigned feedback from approvers.
          </p>
          <div className="w-full flex flex-col gap-4">
            <FormInput
              id={'firstname'}
              label={'First Name'}
              type={'text'}
              placeholder={'Enter first name'}
              onChange={handleApproverChange}
              value={approverData.firstName}
              name={'firstName'}
              hint={errors.firstName}
              error={true}
            />
            <FormInput
              id={'lastname'}
              label={'Last Name'}
              type={'text'}
              placeholder={'Enter last name'}
              onChange={handleApproverChange}
              value={approverData.lastName}
              name={'lastName'}
              hint={errors.lastName}
              error={true}
            />
            <FormInput
              id={'email'}
              label={'Email Address'}
              type={'text'}
              placeholder={'email@swiftapprove.com'}
              onChange={handleApproverChange}
              value={approverData.email}
              name={'email'}
              hint={errors.email}
              error={true}
            />
          </div>
        </>

        <div className="flex justify-between w-full gap-4 mt-8 mb-4">
          <button
            className="border rounded-lg py-2.5 px-5 w-full"
            onClick={closeApproverModal}
          >
            Cancel
          </button>
          <button
            className="bg-primary text-white rounded-lg py-2.5 px-5 w-full"
            onClick={handleAddApprover}
          >
            Add Approver
          </button>
        </div>
      </div>
    </ModalContent>
  );
};

export default AddApproverModal;
