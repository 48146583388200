import React from "react";

const IntegrationCard = ({
  title,
  description,
  icon,
  isEnabled,
  onToggle,
  buttonText,
  integration,
}) => {
  return (
    <div className="p-4 mt-6 bg-white border border-gray-200 rounded-lg shadow-sm w-96">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <img src={icon} alt={`${title} icon`} className="w-12 h-12" />
          <h3 className="text-lg font-medium text-gray-900">{title}</h3>
        </div>
        <label className="relative inline-block w-10 h-6">
          <input
            type="checkbox"
            className="peer sr-only"
            checked={integration?.isConnected}
            onChange={onToggle}
          />
          <span className="absolute top-0 left-0 w-10 h-6 bg-gray-300 rounded-full transition peer-checked:bg-blue-500"></span>
          <span className="absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition peer-checked:translate-x-4"></span>
        </label>
      </div>
      <p className="mt-4 text-sm text-gray-600">{description}</p>

      <hr className="my-2 mt-4 border-gray-300" />
      {/* <div className="flex justify-end"> */}
      {integration.isConnected && (
        <button className="w-full px-4 py-2 text-right text-blue-800">
          {buttonText}
        </button>
      )}
      {/* </div> */}
    </div>
  );
};

export default IntegrationCard;
