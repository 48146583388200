
function toTitleCase(str) {
    return str?.replace(/\w\S*/g, function(txt) {
      return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
    });
  }

function truncateString(str, num = 20) {
    if (!str) {
      return;
    }

    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }
  
  export { toTitleCase, truncateString };
  