import CheckboxWithTick from '../CheckBox';

const Approver = ({ approver, removeApprover, isEdit }) => {
  const handleRemoveApprover = () => {
    removeApprover(approver.email);
  };

  return (
    <div className="flex items-center justify-between gap-3 py-2">
      <div className="flex items-center gap-3">
        <div className="border rounded-full h-10 w-10 flex items-center justify-center font-semibold bg-gray-200 border-gray-300">
          {approver.firstName?.[0]}{approver.lastName?.[0]}
        </div>
        <div className="flex flex-col justify-center text-sm ">
          <div className="font-semibold">
            {approver.firstName} {approver.lastName}
          </div>
          <div className="text-gray-500">{approver.email}</div>
        </div>
      </div>
      {!isEdit && (
        <div>
          <CheckboxWithTick
            onSelect={handleRemoveApprover}
            partial={true}
            forced
          />
        </div>
      )}
    </div>
  );
};

export default Approver;
