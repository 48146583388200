import React, { useState, useRef, useEffect } from 'react';
import CommentFileAttachment from './Comment/CommentFileAttachment'
import DocumentAttachment from './Comment/DocumentAttachment';

const Description = ({ description, attachments, setAttachments }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(descriptionRef.current).lineHeight,
        10
      );
      const maxHeight = lineHeight * 2;
      setIsOverflowing(descriptionRef.current.scrollHeight > maxHeight);
    }
  }, [description]);

  return (
    <div className="border-b px-6 pb-1 flex flex-col gap-0.5 relative text-sm">
      <p className="font-semibold sticky top-0 bg-white pt-3">Description</p>
      <div className="gap-3 text-xs text-gray-700 grow relative">
      <div
          className={`mt-1 transition-all duration-300 static group ${
            isExpanded
              ? 'max-h-40 overflow-x-scroll no-scrollbar'
              : 'overflow-hidden'
          } `}
          style={{
            maxHeight: isExpanded ? '4.5rem' : '2rem',
          }}
        >
          <p
            ref={descriptionRef}
            className="text-xs"
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
          <div
            className={`absolute bottom-0 right-0 font-semibold bg-white ${
              isExpanded ? "invisible group-hover:visible" : ""
            }`}
          >
          </div>
          <div className={`absolute bottom-0 right-0 font-semibold bg-white ${isExpanded ? "invisible group-hover:visible":""}`}>{isOverflowing && (
        <button
          className="text-xs"
          onClick={() => {
            descriptionRef.current.scrollIntoView({ behavior: 'smooth' });
            setIsExpanded((prev) => !prev);
          }}
        >
          {isExpanded ? '...see less' : '...see more'}
        </button>
      )}</div>
        </div>
        <div className="pt-2 flex flex-col gap-1">
          {attachments.map((attachment, idx) => {
            return (
              <DocumentAttachment
                attachment={attachment}
                key={attachment._id ?? idx}
                pos={idx + 1}
                // deleteAction={() => {
                //   setAttachments(attachments.filter((_, index) => index !== idx));
                // }}
              />
            );
          })}
        </div>

      </div>
      
    </div>
  );
};

export default Description;
