const TwoFactorAuthOption = ({
  isEnabled,
  type,
  description,
  maskedValue,
  icon,
  onToggle,
  onSetup,
  onDeactivate,
}) => {
  
  return (
    <div className="flex items-center w-[450px] border border-gray-200 rounded-lg p-4 mt-6">
      <img
        src={icon}
        alt={`${type} Authentication`}
        className="w-16 h-16 mr-4 mb-6"
      />
      <div className="w-full">
        <div className="flex justify-between items-center mb-2">
          <h4 className="text-md text-lg font-semibold">{`${type} Authentication`}</h4>
          {/* {isEnabled && (
            <label
              htmlFor={`${type.toLowerCase()}-toggle`}
              className="flex items-center cursor-pointer"
            >
              <div className="relative">
                <input
                  type="checkbox"
                  id={`${type.toLowerCase()}-toggle`}
                  className="sr-only"
                  checked={isEnabled}
                  onChange={onToggle}
                />
                <div className="block w-10 h-6 bg-gray-200 rounded-full"></div>
                <div
                  className={`absolute left-1 top-1 w-4 h-4 bg-white rounded-full shadow-md transform transition ${
                    isEnabled ? "translate-x-4 bg-gray-300" : "translate-x-0"
                  }`}
                ></div>
              </div>
            </label>
          )} */}
        </div>
        <p className="text-sm font-semibold text-gray-600 mb-3">{description}</p>
        {isEnabled ? (
          <div>
            <div className="flex items-center">
              <span className="font-medium border px-2 py-2 rounded-md text-gray-700">
                {maskedValue}
              </span>
              <button
                className="bg-red-100 text-red-600 font-semibold px-4 py-2 ml-6 rounded-lg border border-red-200 hover:bg-red-300 transition"
                onClick={onDeactivate}
              >
                Deactivate
              </button>
            </div>
          </div>
        ) : (
          <button
            className="bg-blue-100 text-blue-700 text-sm font-semibold px-4 py-2 rounded-lg border border-blue-200 hover:bg-blue-300"
            onClick={onSetup}
            disabled={type === "SMS"}
          >
            { type === "SMS" ? "Coming soon" : "Set up Now"}
          </button>
        )}
      </div>
    </div>
  );
};

export default TwoFactorAuthOption;
