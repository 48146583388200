import React, { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    subject: '',
    message: '',
  });
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleContactSubmit = async () => {
    // Logic to send an email with the form details
    // You might want to use a backend service or a third-party service to send emails
    try {
      await axios.post('/api/auth/user/contact-us', formData);

      toast({
        title: 'Your request was sent.',
        description: "You'll here from us shortly.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setFormData((prevData) => ({
        ...prevData,
        subject: '',
        message: '',
      }));
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an error submitting your request',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="max-w-2xl mt-2 bg-white">
      <div className="border-b">
        <h2 className="text-lg font-semibold mb-2">Contact Us</h2>
        <p className="text-gray-600 mb-6">Let us know how we can help</p>
      </div>
      <form onSubmit={handleContactSubmit} className="space-y-6 mt-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label
              htmlFor="firstName"
              className="text-gray-700 font-medium text-sm leading-[1.42857] font-inter"
            >
              First name
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="First name"
              value={formData.firstName}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-200 focus:border-blue-200 sm:text-sm"
            />
          </div>
          <div>
            <label
              htmlFor="lastName"
              className="text-gray-700 font-medium text-sm leading-[1.42857] font-inter"
            >
              Last name
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder="Last name"
              value={formData.lastName}
              onChange={handleChange}
              required
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-200 focus:border-blue-200 sm:text-sm"
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="subject"
            className="text-gray-700 font-medium text-sm leading-[1.42857] font-inter"
          >
            Subject
          </label>
          <input
            type="text"
            id="subject"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-200 focus:border-blue-200 sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="message"
            className="text-gray-700 font-medium text-sm leading-[1.42857] font-inter"
          >
            Message
          </label>
          <textarea
            id="message"
            name="message"
            placeholder="Let us know how we can help..."
            value={formData.message}
            onChange={handleChange}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-200 focus:border-blue-200 sm:text-sm h-32"
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 text-semibold rounded-md text-medium font-medium hover:bg-blue-700 transition"
        >
          Send message
        </button>
      </form>
    </div>
  );
};

export default ContactUs;
