import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AiFillCheckCircle } from 'react-icons/ai';
import { useRecoilState } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import { MdEdit, MdOutlinePhonelink } from 'react-icons/md';
import { BsFillSendFill } from 'react-icons/bs';
import formatDateAbrev from '../utils/formatDateAbrev';
import foramtDateComments from '../utils/foramtDateComments';
import { useToast } from '@chakra-ui/react';
import { FaLink } from 'react-icons/fa';

import logo from '../icons/workspacePlaceholderIcon.png';
import { darkerShade } from '../utils/darkerShade';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { isCharCoumtOverLimit } from '../utils/validateUtils';
import { AvatarComponent } from 'avatar-initials';
import { currentTimeAtom } from '../recoil/currentTimeAtom';
import { getRandomPastelColor } from '../utils/pastels';
import { FaPlay, FaPause } from 'react-icons/fa';
import { GoScreenFull } from 'react-icons/go';
import { FaRegWindowClose } from 'react-icons/fa';
// import { scrubIframeDoc } from '../utils/remove-anchor-href';
import DraggableAvatar from '../components/DraggableAvatar';
import useIframe from '../hooks/useIframe';
import Iframe from '../components/IFrame';
import CommentSection from '../components/Comment/CommentSection';
import TextAreaComponent from '../components/Reply/TextArea';
import PdfPreview from '../components/DocumentViewers/PdfPreview';
import DocPreview from '../components/DocumentViewers/DocPreview';
import { Logger } from '../utils/logger';
import ModalContent from '../components/Modal/ModalContent';
import DocumentAttachment from "../components/Comment/DocumentAttachment";

const ReviewApprovalRequest = () => {
  const history = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [appReq, setAppReq] = useState({});
  const [sender, setSender] = useState('');
  const [reviewer, setReviewer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [commentCoords, setCommentCoords] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [imageDimensions, setImageDimensions] = useState(null);
  const imageRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [selectedCommentID, setSelectedCommentID] = useState('');
  const commentRefs = useRef({});
  const showInputRef = useRef(null);
  const iframeRef = useRef(null);
  const newCommentRef = useRef(null);
  const [emailColorMap, setEmailColorMap] = useState(new Map());
  const [pageId, setPageId] = useState('');

  const toast = useToast();
  const [currentSlide, setCurrentSlide] = useState(0);
  // State to store the media index
  const [mediaIndex, setMediaIndex] = useState(null);
  const [isLoading2, setIsLoading2] = useState(false);
  const [loadingPostNewComment, setLoadingPostNewComment] = useState(false);
  const addCommentButtonRef = useRef(null);

  const urlParams = new URLSearchParams(window.location.search);
  const reviewerEmail = urlParams.get('reviewer');
  const reviewerId = urlParams.get('reviewer');
  const approvalRequestId = urlParams.get('aprReqID');
  const [isDesktopView, setIsDesktopView] = useState(true);

  const [toggleShowMore, setToggleShowMore] = useState(true);

  // COMMENTS STATE(s)
  const [comments, setComments] = useState([]);
  const [users, setUsers] = useState([]);
  const [newComment, setNewComment] = useState(null);
  const scrollableDivRef = useRef(null);
  const [user, _] = useRecoilState(userAtom);
  const [currentTime] = useRecoilState(currentTimeAtom);

  const [addingComment, setAddingComment] = useState(false);
  const [segmentSelected, setSegmentSelected] = useState(false);
  const [timelineApprovalRequests, setTimelineApprovalRequests] = useState([]);
  const [timelineID, setTimelineID] = useState(null);
  const [workspaceIcon, setWorkspaceIcon] = useState(null);
  const [requestNewLink, setRequestNewLink] = useState(false);
  const [commentSent, setCommentSent] = useState(false);

  const [showRevisionModal, setShowRevisionModal] = useState(false);
  const [showExpandedViewModal, setShowExpandedViewModal] = useState(false);
  const [revisionSent, setRevisionSent] = useState(false);

  const [activeLabel, setActiveLabel] = useState('comments');

  // Document Proofing
  const [selectionIds, setSelectionIds] = useState([]);
  const [isDocumentComment, setIsDocumentComment] = useState(false);

  // End Document Proofing

  const parentDivRef = useRef();

  const { scrubIframeDoc, handleComment, comment } = useIframe();

  const handleLabelClick = (label) => {
    setActiveLabel(label);
  };

  const assignPastelColorsToCommenters = (comments) => {
    let updatedMap = new Map(emailColorMap);

    const updatedComments = comments.map((comment) => {
      if (!updatedMap.has(comment.sender.email)) {
        updatedMap.set(comment.sender.email, getRandomPastelColor());
      }
      return { ...comment, bg: updatedMap.get(comment.sender.email) };
    });

    setEmailColorMap(updatedMap); // Update state with the new map
    return updatedComments;
  };

  const handleAddCommentClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (appReq.content?.media[currentSlide]?.media == 'video') {
        parentDivRef.current.scrollTop = parentDivRef.current.scrollHeight;
      }
      setAddingComment((prevAddingComment) => !prevAddingComment);
      setSegmentSelected(false);
      handleComment(!comment);
    },
    [comment]
  );

  useEffect(() => {
    let usersObj = {};

    if (sender) {
      usersObj[sender.email] = {
        id: sender.id,
        email: sender.email,
        name: sender.firstName,
      };
    }

    appReq?.approvers?.forEach((approver) => {
      usersObj[approver.email] = {
        id: approver._id,
        email: approver.email,
        name: approver.firstName,
      };
    });

    comments.forEach((comment) => {
      usersObj[comment.sender.email] = {
        id: comment.sender.id,
        email: comment.sender.email,
        name: comment.sender.user?.firstName,
      };

      let replies = comment.replies || [];

      replies.forEach((reply) => {
        if (!usersObj[reply.email]) {
          usersObj[reply.email] = {
            id: reply.user?.id,
            email: reply.email,
            name: reply.user?.firstName,
          };
        }
      });
    });
    if (user && !usersObj[user?.email]) {
      usersObj[user.email] = {
        id: user?.id,
        email: user?.email,
        name: user?.firstName,
      };
    }

    if (reviewer && !usersObj[reviewer?.email]) {
      usersObj[reviewer.email] = {
        email: reviewer.email,
        name: reviewer.firstName,
      };
    }

    setUsers(Object.values(usersObj));
  }, [comments, sender]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/api/approvalRequests/get/${approvalRequestId}?reviewer=${
          reviewerId ? reviewerId : ''
        }&firebaseUID=${user?.firebaseAuthUUID}`
      );

      const data = response.data.approvalRequest;
      setAppReq(data);
      setTimelineApprovalRequests(response.data.timeline);
      setWorkspaceIcon(response.data.workspaceIcon);
      setTimelineID(response.data.timelineID);
      setSender(response.data.sender);
      setReviewer(
        data.approvers.find(
          (approver) =>
            approver._id === reviewerId || approver.email === reviewerId
        )
      );
      setIsLoading(false);
    } catch (error) {
      Logger.error('Error fetching data: ', error);
      if (error.response?.data?.message === 'Approver has expired') {
        // history('/workspaces');
        setRequestNewLink(true);
      } else if (error.response?.status === 401) {
        toast({
          title: 'Unauthorized',
          description: 'You do not have permission to view this page.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });

        history(`/login?login_to=${encodeURIComponent(window.location.href)}`);
      }
    } finally {
    }
  };

  function convertSecondsToTimestamp(seconds) {
    if (seconds < 60) {
      return `${Math.floor(seconds)} sec`;
    } else {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes} min ${remainingSeconds} sec`;
    }
  }

  const fetchComments = async () => {
    setIsLoading2(true);
    try {
      const response = await axios.get(
        `/api/approvalRequests/get/${approvalRequestId}?reviewer=${
          reviewerId ? reviewerId : ''
        }&firebaseUID=${user?.firebaseAuthUUID}`
      );
      const updatedComments = assignPastelColorsToCommenters(
        response.data.comments
      );
      setComments(updatedComments);
    } catch (error) {
      Logger.error('Error fetching comments:', error);
    }
    setIsLoading2(false);
  };

  const scrollToComment = (commentID) => {
    setTimeout(() => {
      const commentElement = commentRefs.current[commentID]?.current;
      if (commentElement) {
        commentElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 70); // Wait for 0.5 seconds before executing
  };

  useEffect(() => {
    // Make an HTTP request to your backend using axios
    if (!reviewerId) {
      return;
    }

    axios
      .post('/api/approvalRequests/viewRequest', {
        reviewerEmail,
        reviewerId,
        approvalRequestId,
      })
      .then((response) => {})
      .catch((error) => {});
  }, []);

  useEffect(() => {
    fetchComments();
  }, []);

  useEffect(() => {
    const updateWidth = () => {
      // setMaxWidth(`${(window.innerWidth * 5) / 12}px`);
    };
    window.addEventListener('resize', updateWidth);
    updateWidth(); // initialize maxWidth
    fetchData();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect();
        setImageDimensions({
          width: rect?.width,
          height: rect?.height,
          left: rect?.left,
          top: rect?.top,
        });
      }
    };
    handleResize();

    // Adding an event listener to handle window resize
    window.addEventListener('resize', handleResize);
    if (parentDivRef.current) {
      parentDivRef.current.addEventListener('scroll', handleResize);
    }
    window.addEventListener('scroll', handleResize); // consider when the page scrolls

    return () => {
      window.removeEventListener('resize', handleResize);
      if (parentDivRef.current) {
        parentDivRef.current.removeEventListener('scroll', handleResize);
      }
      window.removeEventListener('scroll', handleResize);
    }; // Cleanup the event listener on unmount
  }, [imageRef.current, toggleShowMore]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        addingComment &&
        showInputRef?.current &&
        !showInputRef?.current.contains(event.target) &&
        imageRef?.current &&
        !imageRef?.current.contains(event.target) &&
        addCommentButtonRef?.current &&
        !addCommentButtonRef?.current.contains(event.target) &&
        !event.target.classList.value.includes('mentions-pop')
      ) {
        setAddingComment(false);
      } else if (
        addCommentButtonRef?.current &&
        addCommentButtonRef?.current.contains(event.target)
      ) {
        setAddingComment(true);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [addingComment, showInputRef, imageRef, addCommentButtonRef]);

  // Document Proofing
  useEffect(() => {
    if (selectionIds.length) {
      setIsDocumentComment(true);
    }
  }, [selectionIds]);

  // End Document Proofing

  const handleApprove = () => {
    setSelectedStatus('approved');
    setShowModal(true);
  };

  const handleConfirm = async () => {
    try {
      setShowModal(false);
      const aprReqID = urlParams.get('aprReqID');
      // const email = urlParams.get('reviewer'); // Replace with the current user's email
      const email = reviewerId ? reviewer.email : user.email;

      await axios.put('/api/approvalRequests/updateStatus', {
        aprReqID,
        email,
        status: selectedStatus,
      });
      //  alert("Status updated successfully");
      toast({
        title: 'Request Approved.',
        duration: 5000,
        isClosable: true,
      });
      fetchData();
    } catch (error) {
      Logger.error('Error updating status: ', error);
      //  alert("Failed to update status");
      toast({
        title: 'Error: Unable to approve request.',
        duration: 5000,
        status: 'error',
        isClosable: true,
      });
    }
  };

  const handleGetNewLink = async () => {
    try {
      const response = await axios.post('/api/approvalRequests/get-new-link', {
        aprReqID: approvalRequestId,
        reviewerId: reviewerId,
      });
      toast({
        title: 'New link sent.',
        description: 'A new link has been sent to the approver.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setRequestNewLink(false);
      history('/workspaces');
    } catch (error) {
      Logger.error('Error getting new link:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while getting a new link.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleImageClick = (e) => {
    if (addingComment && imageDimensions) {
      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left; // x position within the element.
      const y = e.clientY - rect.top; // y position within the element.
      const relativeX = x / imageDimensions.width;
      const relativeY = y / imageDimensions.height;

      // Store these in state and use for the comment submission
      setMediaIndex(currentSlide); // Assuming currentSlide state holds the index of the current media
      setCommentCoords({
        x: relativeX,
        y: relativeY,
        absoluteX: e.clientX,
        absoluteY: e.clientY,
      });
      setShowInput(true);
    }
  };

  const handleImageLoaded = () => {
    setImageLoaded(true); // Set the image as loaded
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      setImageDimensions({
        width: rect.width,
        height: rect.height,
        left: rect.left,
        top: rect.top,
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.shiftKey) {
        newCommentRef.current.value += '\n';
      } else {
        handleNewComment();
      }
    }
  };

  const handleNewComment = async (_id, _content, mentions) => {
    setLoadingPostNewComment(true);
    const approvalRequestID = urlParams.get('aprReqID');
    // const reviewerEmail = urlParams.get('reviewer');
    const reviewerEmail = reviewerId ? reviewer.email : user.email;
    const currentMediaType = appReq.content?.media[currentSlide]?.media;

    let commentData = {
      approvalRequestID,
      comment: newCommentRef.current?.value
        ? newCommentRef.current.value
        : showInputRef.current.value,
      senderEmail: reviewerEmail,
      mediaIndex: mediaIndex ? mediaIndex : currentSlide,
      mentions,
    };
    // setShowInput(false);
    // setAddingComment(false);

    // Check if the current media is a video and add timestamp
    if (currentMediaType === 'video') {
      commentData = {
        ...commentData,
        coords: {
          videoTimestamp: imageRef.current?.currentTime ?? currentTime,
          x: commentCoords?.x,
          y: commentCoords?.y,
        },
      };
    } else if (['pdf', 'doc'].includes(currentMediaType)) {
      commentData = {
        ...commentData,
        selectionIds: selectionIds,
      };
      // If there was already a selection, remove highlight.
      const tempElems = document.getElementsByClassName('temp-selection');
      const elemsArray = Array.from(tempElems);

      elemsArray.forEach((elem) => {
        // Remove the 'temp-selection' class from each element to deselect
        elem.classList.remove('temp-selection');
      });
    } else {
      // For images, use existing coordinates logic
      commentData = {
        ...commentData,
        coords: {
          x: commentCoords?.x,
          y: commentCoords?.y,
        },
      };
    }

    setNewComment(commentData);
    setAddingComment(false);

    try {
      const response = await axios.post('/api/comments/post', commentData);
      const aprReqID = urlParams.get('aprReqID');
      // const email = urlParams.get('reviewer'); // Replace with the current user's email
      const email = reviewerId ? reviewer.email : user.email;
      await axios.put('/api/approvalRequests/updateStatus', {
        aprReqID,
        email,
        status: 'needsRevison',
      });

      const updatedComments = assignPastelColorsToCommenters([
        ...comments,
        response.data,
      ]);

      setComments(updatedComments);
      setNewComment(null);
      toast({
        title: 'Comment sent.',
        duration: 5000,
        isClosable: true,
      });
      setCommentSent(true);
      // window.location.reload(true)
    } catch (error) {
      toast({
        title: 'Unable to send comment.',
        duration: 5000,
        status: 'error',
        isClosable: true,
      });
      // Logger.error('Error posting new comment:', error);
    } finally {
      setSelectionIds([]);
      setShowInput(false);
      setLoadingPostNewComment(false);
    }
  };

  const toggleResolved = async (_id) => {
    try {
      const response = await axios.post(`/api/comments/toggle-resolved/${_id}`);
      const data = await response.data;

      if (data.success) {
        toast({
          title: 'Status updated',
          description: 'The resolved status has been updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Optionally, trigger a state update to re-fetch or adjust the comments displayed
        fetchComments();
      } else {
        toast({
          title: 'Error',
          description: data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while resolving the comment',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const deleteComment = async (_id) => {
    try {
      const response = await axios.delete(
        `/api/comments/delete-comment/${_id}`
      );
      const data = response.data;

      if (data.success) {
        toast({
          title: 'Comment Deleted',
          description: 'The comment has been successfully deleted.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Optionally, trigger a state update to re-fetch or adjust the comments displayed
        fetchComments();
      } else {
        toast({
          title: 'Error',
          description: data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      Logger.error('Error deleting comment:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while deleting the comment',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateComment = async (commentId, newComment, mentions) => {
    try {
      const response = await axios.patch(
        `/api/comments/update-comment/${commentId}`,
        { comment: newComment, mentions }
      );
      const data = response.data;

      if (data.success) {
        toast({
          title: 'Comment Updated',
          description: 'The comment has been updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchComments();
        // Optionally, trigger a state update to re-fetch or adjust the comments displayed
      } else {
        toast({
          title: 'Error',
          description: data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      Logger.error(error);
      toast({
        title: 'Error',
        description: 'An error occurred while updating the comment.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const VersionCard = ({ index, request }) => {
    const handleClick = () => {
      // Construct the new URL with the request._id query parameter
      const url = `/review?${
        reviewerId ? `reviewer=${reviewer?._id}&` : ''
      }aprReqID=${request._id}`;
      history(url); // This changes the URL and navigates to the new route
      window.location = window.location;
    };

    return (
      <div
        className={`px-4 py-4 hover:m-2 rounded-lg my-2 pr-20 cursor-pointer w-4/5 bg-${
          request._id == approvalRequestId ? 'gray-50' : 'white'
        }`}
        index={index}
        style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)' }}
        onClick={() => handleClick()}
      >
        <p className="text-xl font-bold">Version {index + 1}</p>
        <p className="text-secondary leading-4">
          {request.approvalTitle.trim()}
        </p>
        <p className="text-secondary leading-4 text-sm">
          {foramtDateComments(request.dateCreated)}
        </p>
      </div>
    );
  };

  const handleDragStop = async (e, data, comment, absoluteX, absoluteY) => {
    let x = (data.x + absoluteX - imageDimensions.left) / imageDimensions.width;
    let y = (data.y + absoluteY - imageDimensions.top) / imageDimensions.height;
    const newRelativeX = x;
    const newRelativeY = y;

    // Update the comment position in the backend
    handleLabelClick(comment?.resolved ? 'resolved' : 'comments');
    await updateCommentCoords(comment._id, {
      x: newRelativeX,
      y: newRelativeY,
    });
  };

  const updateCommentCoords = async (commentId, coords) => {
    try {
      const response = await axios.patch(
        `/api/comments/update-coords/${commentId}`,
        { coords }
      );
      const data = response.data;

      if (data.success) {
        // toast({
        //   title: "Comment Updated",
        //   description: "The comment's position has been updated successfully.",
        //   status: "success",
        //   duration: 5000,
        //   isClosable: true,
        // });
        await fetchComments(); // Re-fetch comments to update the UI
      } else {
        toast({
          title: 'Error',
          description: data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      Logger.error(error);
      toast({
        title: 'Error',
        description: "An error occurred while updating the comment's position.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const LeftArrow = ({ onClick, isVisible }) =>
    isVisible && (
      <div
        className="absolute left-0 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 nav-arrow"
        onClick={onClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '50px',
          height: '50px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '50%',
          color: 'white',
        }}
      >
        <FaChevronLeft size={30} />
      </div>
    );

  const RightArrow = ({ onClick, isVisible }) =>
    isVisible && (
      <div
        className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 nav-arrow"
        onClick={onClick}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '50px',
          height: '50px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '50%',
          color: 'white',
        }}
      >
        <FaChevronRight size={30} />
      </div>
    );

  const allAttachments = appReq?.reduce((acc, item) => {
    if (item.attachments && Array.isArray(item.attachments)) {
      return acc.concat(item.attachments);
    }
    return acc;
  }, []);

  return (
    <div className="flex flex-1 h-screen p-6 flex-col px-0">
      <div className="justify-between mt-4 flex flex-row px-6">
        {" "}
        {/* Top Bar  */}
        <div className="flex-1">
          {" "}
          {/* BACK BUTTON  */}
          <p
            className={`text-${
              user?.email ? "secondary" : "white"
            } text-xl cursor-pointer hover-bg hover-shadow rounded w-fit `}
            onClick={() =>
              user.email
                ? history(`/approvalRequest/${timelineID}`)
                : history(-1)
            }
          >
            {user?.email ? "< Back" : "."}
          </p>
        </div>
        <div className="flex-1 text-center">
          <p className="text-2xl font-black">Approval Request</p>
        </div>
        <div
          className="text-end flex flex-1 justify-end"
          style={{ whiteSpace: "nowrap", overflow: "visible" }}
        >
          {" "}
          {/* APPROVE BUTTON  */}
          <button
            className="bg-dashGray text-primary px-4 rounded-lg py-1 hover-bg hover-shadow mx-2"
            ref={addCommentButtonRef}
            onClick={handleAddCommentClick}
          >
            {addingComment ? "Cancel Comment" : "+ Add Comment"}
          </button>
          {!user?.email && !revisionSent && commentSent && (
            <div>
              <button
                style={{
                  backgroundColor: "#FFBB6A",
                  color: "#fff",
                }}
                className="text-xl px-4 rounded-xl py-1 mx-2 hover-shadow"
                onClick={() => setShowRevisionModal(true)}
              >
                <span className="flex items-center">
                  {" "}
                  <MdEdit className="mr-1" size={24} /> Send for revision
                </span>
              </button>
            </div>
          )}
          {!user?.email && revisionSent && !commentSent && (
            <div>
              <button
                style={{
                  border: "2px solid #FFBB6A",
                  color: darkerShade("#FFBB6A", 0.55),
                }}
                className="text-xl px-4 rounded-xl py-1 mx-2 cursor-default"
              >
                <span className="flex items-center">
                  {" "}
                  <MdEdit className="mr-1" size={24} /> Sent
                </span>
              </button>
            </div>
          )}
          {(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const reviewerId = urlParams.get("reviewer");
            const approverObj = appReq.approvers?.find(
              (approver) =>
                approver.email === reviewerId || approver._id === reviewerId
            );

            if (approverObj && user?.email != reviewer?.email) {
              if (
                approverObj.status === "pending" ||
                approverObj.status === "needsRevison"
              ) {
                return (
                  <div>
                    <button
                      className="text-xl bg-green-700 hover:bg-green-800 hover-shadow text-white px-4 rounded-xl py-1"
                      onClick={() => handleApprove()}
                    >
                      <span className="flex items-center">
                        <AiFillCheckCircle className="mr-1" size={24} /> Approve{" "}
                      </span>
                    </button>
                  </div>
                );
              } else if (approverObj.status === "approved") {
                return (
                  <button className="text-xl border-2 border-green-700  text-green-700 bg-white px-4 rounded-xl py-1 cursor-default">
                    <span className="flex items-center">
                      <AiFillCheckCircle className="mr-1" size={24} /> Request
                      Approved{" "}
                    </span>
                  </button>
                );
              }
            }

            return null; // or return some default element if needed
          })()}
        </div>
      </div>

      <div className="mt-8 flex flex-row justify-between flex-1">
        {" "}
        {/*  VERSION + CONTENT + COMMENTS SECTION */}
        <div className="" style={{ width: "25%" }}>
          {/* Approval Information */}
          <p className="font-bold text-xl pl-10 mb-4">Approval Information</p>
          <div
            className={`flex flex-col justify-between px-4 py-4 rounded-lg mt-2 w-4/5 mb-4`}
            style={{
              boxShadow: "0px 0px 35px rgba(0, 0, 0, 0.1)",
              height: "30vh",
              margin: "0 auto",
            }}
          >
            <div className="">
              <p className="font-bold text-2xl">{appReq?.approvalTitle}</p>

              <div className="flex flex-row mt-2">
                {sender.profilePicURL && (
                  <img
                    src={sender.profilePicURL}
                    className="h-7 w-7 mr-2"
                    style={{ border: "1px solid #ccc", borderRadius: "50%" }}
                  />
                )}

                <div className="flex flex-col">
                  <p className="text-sm text-secondary">{sender.fullName}</p>
                  <p className="text-xs text-secondary mb-2">
                    Sent on {formatDateAbrev(appReq?.dateCreated)}
                  </p>
                </div>
              </div>

              <p className="text-sm font-semibold text-secondary">
                Description
              </p>
              <div
                className="mt-1 overflow-auto"
                style={{ maxHeight: "100px" }}
              >
                {" "}
                {/* Adjust max-height as needed */}
                <p
                  style={{ whiteSpace: "pre-wrap" }}
                  className="text-sm text-secondary"
                >
                  {appReq?.description}
                </p>
                <div className="pt-2 flex flex-col gap-1">
                  {allAttachments.map((attachment, idx) => {
                    return (
                      <DocumentAttachment
                        attachment={attachment}
                        key={attachment._id ?? idx}
                        pos={idx + 1}
                        // deleteAction={() => {
                        //   setAttachments(attachments.filter((_, index) => index !== idx));
                        // }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>

            <p className="text-sm text-secondary font-bold">
              Due: {formatDateAbrev(appReq?.dueDate)}
            </p>
          </div>

          {/* Versions */}
          <p className="font-bold text-xl mt-3 pl-10">Versions</p>

          <div
            className="w-full pl-10 no-scrollbar"
            style={{ maxHeight: "30vh", overflowY: "auto" }}
          >
            {" "}
            {/* VERSIONS */}
            {isLoading ? (
              <div className="blackSpinner"></div>
            ) : (
              <div className="w-full py-4">
                {timelineApprovalRequests.map((request, index) => (
                  <VersionCard index={index} request={request} key={index} />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="" style={{ width: "50%" }}>
          {" "}
          {/* CONTENT */}
          {isLoading ? (
            <div className="blackSpinner"></div>
          ) : (
            <div
              className={`relative ${
                appReq.content?.media[currentSlide]?.media == "html"
                  ? ""
                  : "w-4/5"
              }`}
              style={{ margin: "0 auto" }}
            >
              <div
                className="p-4 overflow-y-scroll rounded-xl no-scrollbar border"
                style={{
                  boxShadow: "0px 0px 35px rgba(0, 0, 0, 0.1)",
                  maxHeight: "80vh",
                  scrollbarWidth: "none" /* For Firefox */,
                  msOverflowStyle: "none" /* For Internet Explorer and Edge */,
                  WebkitScrollbar: {
                    display: "none" /* For Chrome, Safari, and Opera */,
                  },
                }}
                ref={parentDivRef}
              >
                <div
                  className={`${addingComment ? "target-div" : ""}`}
                  style={{
                    cursor: `${
                      addingComment
                        ? showInput
                          ? "default"
                          : "pointer"
                        : "default"
                    }`,
                    position: "relative",
                  }}
                >
                  {appReq.content?.media[currentSlide]?.media !== "html" && (
                    <div className="flex items-start flex-col w-full pr-4">
                      {appReq?.contentTypeCategory?.subCategory != "other" && (
                        <div className="flex flex-col justify-start mb-1 w-full items-start">
                          <div className="flex flex-row w-full">
                            <img
                              src={workspaceIcon ? workspaceIcon : logo}
                              className="h-8 w-8 mr-2"
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "50%",
                              }}
                            />
                            <p className="font-bold text-lg">{`${appReq?.approvalTitle.trim()}`}</p>
                          </div>
                          <div>
                            {isCharCoumtOverLimit(appReq?.content?.caption) ? (
                              <>
                                {toggleShowMore ? (
                                  <p
                                    style={{ whiteSpace: "pre-wrap" }}
                                    className="text-secondary"
                                  >
                                    {appReq?.content?.caption
                                      .trim()
                                      .substring(0, 100) + "..."}
                                  </p>
                                ) : (
                                  <p
                                    style={{ whiteSpace: "pre-wrap" }}
                                    className="text-secondary"
                                  >
                                    {appReq?.content?.caption.trim()}
                                  </p>
                                )}
                                <p
                                  className="text-primary cursor-pointer"
                                  onClick={() =>
                                    setToggleShowMore(!toggleShowMore)
                                  }
                                >
                                  {toggleShowMore ? "View more" : "View less"}
                                </p>
                              </>
                            ) : (
                              <p className="text-secondary">
                                {appReq?.content?.caption.trim()}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div
                    className="flex justify-center mb-2 your-container"
                    style={{ width: "100%", objectFit: "cover" }}
                  >
                    <LeftArrow
                      onClick={() => setCurrentSlide(currentSlide - 1)}
                      isVisible={!addingComment && currentSlide > 0}
                    />
                    <RightArrow
                      onClick={() => setCurrentSlide(currentSlide + 1)}
                      isVisible={
                        !addingComment &&
                        currentSlide < appReq.content?.media.length - 1
                      }
                    />

                    {appReq?.content?.media &&
                      appReq.content?.media.length > 0 && (
                        <>
                          {appReq.content?.media[currentSlide]?.media ===
                          "video" ? (
                            <VideoPlayer
                              src={appReq.content?.media[currentSlide]?.src}
                              videoRef={imageRef}
                              comments={comments}
                              imageDimensions={imageDimensions}
                              setMediaIndex={setMediaIndex}
                              currentSlide={currentSlide}
                              setCommentCoords={setCommentCoords}
                              addingComment={addingComment}
                              sender={sender}
                              setSelectedCommentID={setSelectedCommentID}
                              handleLabelClick={handleLabelClick}
                              scrollToComment={scrollToComment}
                              setShowExpandedViewModal={
                                setShowExpandedViewModal
                              }
                              showExpandedViewModal={showExpandedViewModal}
                              selectedCommentID={selectedCommentID}
                              isPlaying={isPlaying}
                              setIsPlaying={setIsPlaying}
                            />
                          ) : appReq.content?.media[currentSlide]?.media ===
                            "html" ? (
                            <div
                              className={
                                addingComment
                                  ? "adding-comment rounded-lg cursor-comment"
                                  : "rounded-lg"
                              }
                              style={{
                                // overflow: 'hidden',
                                width: "100%",
                                maxHeight: "70vh",
                              }}
                            >
                              <div className="flex justify-between">
                                {/* <MdOutlinePhonelink
                                  size={30}
                                  className="text-secondary cursor-pointer mx-4"
                                  onClick={() =>
                                    setIsDesktopView(!isDesktopView)
                                  }
                                />
                                <GoScreenFull
                                  size={30}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setShowExpandedViewModal(
                                      !showExpandedViewModal
                                    )
                                  }
                                /> */}
                              </div>
                              <Iframe
                                onLoad={scrubIframeDoc}
                                scrubIframeDoc={scrubIframeDoc}
                                srcDoc={
                                  appReq.content?.media[currentSlide]?.src
                                }
                                style={{
                                  width: isDesktopView ? "200%" : "60%", // Increase width
                                  height: isDesktopView ? "140vh" : "70vh",
                                  border: "none",
                                  margin: "0 auto",
                                  transform: isDesktopView
                                    ? "scale(0.5)"
                                    : "scale(1.0)",
                                  transformOrigin: "0 0",
                                  position: "relative",
                                  pointerEvents: "none",
                                }}
                                title={`Post ${currentSlide}`}
                                originalRef={iframeRef}
                                comment={comment}
                                onInferredClick={(e) => {
                                  // can add click logic here. not sure if I still need it but will keep for now.
                                }}
                                addingcomment={addingComment}
                                setSegmentSelected={setSegmentSelected}
                                segmentselected={segmentSelected}
                                comments={comments.filter(
                                  (comment) =>
                                    comment.mediaIndex == currentSlide
                                )}
                                currentSlide={currentSlide}
                                setCommentCoords={setCommentCoords}
                                selectedCommentID={selectedCommentID}
                                setSelectedCommentID={setSelectedCommentID}
                                scrollToComment={scrollToComment}
                                user={
                                  reviewer
                                    ? reviewer
                                    : user.email
                                    ? user
                                    : { email: reviewerEmail }
                                }
                                handleClickActions={(iframeRef, e) => {
                                  const clickedY = e.view.scrollY + e.clientY;
                                  const clickedX = e.view.scrollX + e.clientX;

                                  const absoluteY =
                                    clickedY /
                                    e.target.ownerDocument.documentElement
                                      .scrollHeight;
                                  const absoluteX =
                                    clickedX /
                                    e.target.ownerDocument.documentElement
                                      .scrollWidth;

                                  var parentDiv =
                                    iframeRef?.current?.parentElement;

                                  // Get the bounding rectangle of the iframe
                                  var iframeRect =
                                    iframeRef?.current?.getBoundingClientRect();
                                  var parentDivRect =
                                    parentDiv.getBoundingClientRect();

                                  var iframeViewportHeight =
                                    iframeRef?.current?.contentWindow
                                      .innerHeight;
                                  var iframeViewportWidth =
                                    iframeRef?.current?.contentWindow
                                      .innerWidth;

                                  const xRatio =
                                    iframeRect.width / iframeViewportWidth;
                                  const yRatio =
                                    iframeRect.height / iframeViewportHeight;

                                  var clickX =
                                    xRatio * e.clientX + parentDivRect.left;
                                  var clickY =
                                    yRatio * e.clientY + parentDivRect.top;

                                  setCommentCoords({
                                    x: absoluteX,
                                    y: absoluteY,
                                    absoluteX: clickX,
                                    absoluteY: clickY,
                                  });

                                  setShowInput(true);
                                }}
                                reviewerEmail={(reviewer ?? user)?.email}
                              />
                            </div>
                          ) : appReq.content?.media[currentSlide]?.media ===
                            "pdf" ? (
                            <div className="w-full">
                              <PdfPreview
                                data={appReq?.content?.media[currentSlide].src}
                                className={
                                  addingComment
                                    ? "adding-comment rounded-lg cursor-comment"
                                    : "rounded-lg"
                                }
                                id={pageId}
                                setId={setPageId}
                                sender={sender}
                                addingComment={addingComment}
                                slide={currentSlide}
                                setSelectionIds={setSelectionIds}
                                selectedIds={selectionIds}
                                comments={comments}
                              />
                            </div>
                          ) : appReq.content?.media[currentSlide]?.media ===
                            "doc" ? (
                            <DocPreview
                              url={appReq?.content?.media[currentSlide].src}
                              sender={sender}
                              addingComment={addingComment}
                              id={pageId}
                              setId={setPageId}
                              setSelectionIds={setSelectionIds}
                              comments={comments}
                              slide={currentSlide}
                              className={
                                addingComment
                                  ? "adding-comment rounded-lg cursor-comment"
                                  : "rounded-lg"
                              }
                            />
                          ) : (
                            <div
                              style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "65vh",
                                minHeight: "fit-content",
                                marginBottom: "1rem",
                                display: "flex",
                              }}
                            >
                              <img
                                src={appReq.content?.media[currentSlide]?.src}
                                onLoad={handleImageLoaded}
                                alt={`Post ${currentSlide}`}
                                ref={imageRef}
                                className={
                                  addingComment
                                    ? "adding-comment rounded-lg cursor-comment"
                                    : "rounded-lg"
                                }
                                style={{
                                  objectFit: "contain",
                                  margin: "auto",
                                  display: "block",
                                  maxWidth: "100%",
                                  maxHeight: "100%",
                                }}
                                onClick={(e) => handleImageClick(e)}
                              />
                            </div>
                          )}
                        </>
                      )}
                  </div>

                  {appReq?.link.length > 0 && (
                    <div className="w-full rounded-lg mb-1 ml-4 flex flex-row justify-center items-center">
                      <p className="mr-1 text-secondary">
                        <FaLink />
                      </p>
                      <a
                        href={appReq?.link}
                        target="_blank"
                        className="text-secondary  underline"
                      >
                        <p>{appReq?.link}</p>
                      </a>
                    </div>
                  )}

                  {/* Dots Indicator */}
                  <div className="flex justify-center space-x-2">
                    {appReq?.content?.media &&
                      appReq.content?.media.map((_, idx) => (
                        <span
                          key={idx}
                          onClick={() => setCurrentSlide(idx)}
                          className={`inline-block h-2.5 w-2.5 rounded-full cursor-pointer ${
                            currentSlide === idx ? "bg-gray-400" : "bg-gray-200"
                          }`}
                        ></span>
                      ))}
                  </div>

                  {showInput && (
                    <div
                      style={{
                        position: "fixed",
                        boxShadow: "0px 0px 35px rgba(0, 0, 0, 0.2)",
                        zIndex: "99",
                        left: `${commentCoords?.absoluteX}px`,
                        top: `${commentCoords?.absoluteY}px`,
                      }}
                      className={
                        addingComment
                          ? "rounded-xl hover-shadow bg-white p-1 border-gray-200"
                          : "text-primary cursor-pointer bg-gray-50 rounded-r-full rounded-t-full"
                      }
                      // className="rounded-xl hover-shadow bg-white border-gray-200 p-2 "
                    >
                      {addingComment ? (
                        <TextAreaComponent
                          initialValue={""}
                          id={"id"}
                          closeEdit={() => {}}
                          users={users}
                          currentUser={
                            reviewer
                              ? reviewer
                              : user.email
                              ? user
                              : { email: reviewerEmail }
                          }
                          reviewerEmail={(reviewer ?? user)?.email}
                          textareaRef={showInputRef}
                          saveFn={handleNewComment}
                        />
                      ) : (
                        !["video", "html", "doc", "pdf"].includes(
                          appReq.content?.media[currentSlide]?.media
                        ) &&
                        (sender?.profilePicURL != null ? (
                          <img
                            src={sender?.profilePicURL}
                            className={`text-primary cursor-pointer h-8 w-8 rounded-full `}
                          />
                        ) : (
                          <AvatarComponent
                            classes="text-primary cursor-pointer p-1 bg-gray-50 rounded-full"
                            useGravatar={false}
                            color={darkerShade(
                              emailColorMap.get((reviewer ?? user)?.email),
                              0.55
                            )}
                            background={emailColorMap.get(
                              (reviewer ?? user)?.email
                            )}
                            fontSize={16}
                            fontWeight={400}
                            className="text-primary cursor-pointer"
                            width={40}
                            height={40}
                            initials={(reviewer ?? user)?.email
                              .substring(0, 1)
                              .toUpperCase()}
                          />
                        ))
                      )}
                    </div>
                  )}
                  {imageLoaded &&
                    comments.length > 0 &&
                    !["video", "html", "doc", "pdf"].includes(
                      appReq.content?.media[currentSlide]?.media
                    ) &&
                    comments.map((comment) => {
                      if (comment.mediaIndex === currentSlide) {
                        const absoluteX =
                          imageDimensions.left +
                          comment.coords.x * imageDimensions.width;
                        const absoluteY =
                          imageDimensions.top +
                          comment.coords.y * imageDimensions.height;

                        return (
                          <>
                            {(reviewer ?? user)?.email ==
                            comment.sender.email ? (
                              <DraggableAvatar
                                key={comment._id}
                                absoluteX={absoluteX}
                                absoluteY={absoluteY}
                                loading={isLoading2}
                                selectedCommentID={selectedCommentID}
                                setSelectedCommentID={setSelectedCommentID}
                                scrollToComment={scrollToComment}
                                comment={comment}
                                imageDimensions={imageDimensions}
                                handleDragStop={handleDragStop}
                              >
                                <div
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  {comment.sender.email == sender.email &&
                                  sender?.profilePicURL != null ? (
                                    <img
                                      src={sender?.profilePicURL}
                                      className={`text-primary cursor-pointer h-8 w-8 rounded-full `}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setSelectedCommentID(comment._id);
                                        handleLabelClick(
                                          comment?.resolved
                                            ? "resolved"
                                            : "comments"
                                        );
                                        setTimeout(() => {
                                          scrollToComment(comment._id);
                                        }, 30);
                                      }}
                                    />
                                  ) : (
                                    <AvatarComponent
                                      classes="rounded-full"
                                      useGravatar={false}
                                      color={darkerShade(comment.bg, 0.55)}
                                      background={comment.bg}
                                      fontSize={16}
                                      fontWeight={400}
                                      className="text-primary cursor-pointer"
                                      size={32}
                                      initials={comment.sender.email
                                        .substring(0, 1)
                                        .toUpperCase()}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setSelectedCommentID(comment._id);
                                        handleLabelClick(
                                          comment?.resolved
                                            ? "resolved"
                                            : "comments"
                                        );
                                        setTimeout(() => {
                                          scrollToComment(comment._id);
                                        }, 30);
                                      }}
                                    />
                                  )}
                                </div>
                              </DraggableAvatar>
                            ) : (
                              <button
                                style={{
                                  position: "fixed",
                                  left: `${absoluteX}px`,
                                  top: `${absoluteY}px`,
                                }}
                                className={`text-primary cursor-pointer p-1 bg-gray-50 rounded-full ${
                                  selectedCommentID == comment._id
                                    ? "adding-comment"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSelectedCommentID(comment._id);
                                  scrollToComment(comment._id);
                                }}
                                key={comment._id}
                              >
                                <div
                                  onMouseDown={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  {comment.sender.email == sender.email &&
                                  sender?.profilePicURL != null ? (
                                    <img
                                      src={sender?.profilePicURL}
                                      className={`text-primary cursor-pointer h-8 w-8 rounded-full `}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setSelectedCommentID(comment._id);
                                        handleLabelClick(
                                          comment?.resolved
                                            ? "resolved"
                                            : "comments"
                                        );
                                        setTimeout(() => {
                                          scrollToComment(comment._id);
                                        }, 30);
                                      }}
                                    />
                                  ) : (
                                    <AvatarComponent
                                      classes="rounded-full"
                                      useGravatar={false}
                                      color={darkerShade(comment.bg, 0.55)}
                                      background={comment.bg}
                                      fontSize={16}
                                      fontWeight={400}
                                      className="text-primary cursor-pointer"
                                      size={32}
                                      initials={comment.sender.email
                                        .substring(0, 1)
                                        .toUpperCase()}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setSelectedCommentID(comment._id);
                                        handleLabelClick(
                                          comment?.resolved
                                            ? "resolved"
                                            : "comments"
                                        );
                                        setTimeout(() => {
                                          scrollToComment(comment._id);
                                        }, 30);
                                      }}
                                    />
                                  )}
                                </div>
                              </button>
                            )}
                          </>
                        );
                      }
                    })}
                </div>

                {/* Commenting on PDF or DOC */}
                {addingComment &&
                  (appReq.content?.media[currentSlide]?.media == "video" ||
                    (["pdf", "doc"].includes(
                      appReq.content?.media[currentSlide]?.media
                    ) &&
                      isDocumentComment)) && (
                    <div
                      style={{ boxShadow: "0px 0px 35px rgba(0, 0, 0, 0.2)" }}
                      className="rounded-xl hover-shadow bg-white border-gray-200 p-2 mt-2 relative"
                    >
                      <TextAreaComponent
                        initialValue={""}
                        id={"id"}
                        closeEdit={() => {}}
                        users={users}
                        placeholder="Write a new comment..."
                        currentUser={
                          reviewer
                            ? reviewer
                            : user.email
                            ? user
                            : { email: reviewerEmail }
                        }
                        reviewerEmail={(reviewer ?? user)?.email}
                        textareaRef={newCommentRef}
                        saveFn={handleNewComment}
                        mentionsPopPosition={"top"}
                      />
                    </div>
                  )}
              </div>
              <div className="absolute bottom-4 right-4">
                {
                  !["video", "html", "pdf", "doc"].includes(
                    appReq.content?.media[currentSlide]?.media
                  )
                  //  && (
                  //   <GoScreenFull
                  //     size={30}
                  //     className="cursor-pointer"
                  //     onClick={() =>
                  //       setShowExpandedViewModal(!showExpandedViewModal)
                  //     }
                  //   />
                  // )
                }
              </div>
            </div>
          )}
        </div>
        <div style={{ width: "25%" }} className="mr-3 flex justify-end">
          {" "}
          {/* COMMENTS */}
          <div className="w-full">
            <div className="flex flow-row mb-0 ml-3">
              <p
                className={`text-xl text-left cursor-pointer ${
                  activeLabel === "comments"
                    ? "font-extrabold"
                    : "font-normal text-secondary"
                }`}
                onClick={() => handleLabelClick("comments")}
              >
                Comments
              </p>
              <span className="px-3 text-xl">|</span>
              <p
                className={`text-xl text-left cursor-pointer ${
                  activeLabel === "resolved"
                    ? "font-bold"
                    : "font-normal text-secondary"
                }`}
                onClick={() => handleLabelClick("resolved")}
              >
                Resolved
              </p>
            </div>
            <div>
              {isLoading ? (
                <div className="blackSpinner"></div>
              ) : (
                <CommentSection
                  scrollableDivRef={scrollableDivRef}
                  comments={comments}
                  newComment={newComment}
                  activeLabel={activeLabel}
                  reviewerEmail={(reviewer ?? user)?.email}
                  users={users}
                  appReq={appReq}
                  commentRefs={commentRefs}
                  convertSecondsToTimestamp={convertSecondsToTimestamp}
                  deleteComment={deleteComment}
                  imageRef={imageRef}
                  scrollToComment={scrollToComment}
                  selectedCommentID={selectedCommentID}
                  sender={sender}
                  setCurrentSlide={setCurrentSlide}
                  setIsPlaying={setIsPlaying}
                  setSelectedCommentID={setSelectedCommentID}
                  toggleResolved={toggleResolved}
                  updateComment={updateComment}
                  user={
                    reviewer
                      ? reviewer
                      : user.email
                      ? user
                      : { email: reviewerEmail }
                  }
                  setMediaIndex={setMediaIndex}
                  setSelectionId={setPageId}
                />
              )}
            </div>
          </div>
        </div>
        {showModal && (
          <div
            className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-75 flex justify-center items-center"
            style={{ paddingTop: "-10%", zIndex: 1000 }}
          >
            <div
              className="bg-white p-4 rounded-lg py-12"
              style={{ boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.3)" }}
            >
              <div className="justify-center mb-4 text-center">
                <h2 className="font-bold text-2xl">
                  <span className="text-primary">Swift</span>Approve
                </h2>
                <p className="text-md text-gray-500">
                  content approval made easy.
                </p>
              </div>
              <p
                className="text-lg w-2/3 font-bold text-center pb-4"
                style={{ margin: "0 auto" }}
              >
                Are you sure you want to{" "}
                {selectedStatus === "approved" ? "Approve" : "Deny"} this
                request?
              </p>

              <div className="justify-around flex mt-4 text-semibold">
                <button
                  onClick={() => setShowModal(false)}
                  className="bg-white border-primary border-2 text-primary px-4 py-1 rounded-lg hover-bg hover-shadow"
                >
                  Cancel
                </button>
                <button
                  onClick={handleConfirm}
                  className="bg-primary text-white px-4 py-1 rounded-lg hover-shadow"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
        {showRevisionModal && (
          <div
            className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-75 flex justify-center items-center"
            style={{ paddingTop: "-10%", zIndex: 10 }}
          >
            <div
              className="bg-white p-4 rounded-lg py-12"
              style={{ boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.3)" }}
            >
              <div className="justify-center mb-4 text-center">
                <h2 className="font-bold text-2xl">
                  <span className="text-primary">Swift</span>Approve
                </h2>
                <p className="text-md text-gray-500">
                  content approval made easy.
                </p>
              </div>
              <p
                className="text-lg w-2/3 font-bold text-center pb-4"
                style={{ margin: "0 auto" }}
              >
                Are you sure you want to send this request back for revisions?
              </p>

              <div className="justify-around flex mt-4 text-semibold">
                <button
                  onClick={() => setShowRevisionModal(false)}
                  className="bg-white border-primary border-2 text-primary px-4 py-1 rounded-lg hover-bg hover-shadow"
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setRevisionSent(true);
                    setShowRevisionModal(false);
                    setCommentSent(false);
                  }}
                  className="bg-primary text-white px-4 py-1 rounded-lg hover-shadow"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
        {showExpandedViewModal && (
          <div
            className="fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-75 flex justify-center items-center"
            style={{ paddingTop: "-10%", zIndex: 100 }}
          >
            <div
              className="relative bg-white p-4 rounded-lg py-12 w-4/5"
              style={{ boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.3)" }}
            >
              <div
                className="absolute top-4 right-4 cursor-pointer"
                onClick={() => {
                  setShowExpandedViewModal(false);
                }}
              >
                <FaRegWindowClose className="mt-3" size={30} />
              </div>
              <div
                className="flex justify-center mb-2 your-container px-4"
                style={{ width: "100%", objectFit: "cover" }}
              >
                <LeftArrow
                  onClick={() => setCurrentSlide(currentSlide - 1)}
                  isVisible={!addingComment && currentSlide > 0}
                />
                <RightArrow
                  onClick={() => setCurrentSlide(currentSlide + 1)}
                  isVisible={
                    !addingComment &&
                    currentSlide < appReq.content?.media.length - 1
                  }
                />

                {appReq?.content?.media && appReq.content?.media.length > 0 && (
                  <>
                    {appReq.content?.media[currentSlide]?.media === "video" ? (
                      <VideoPlayer
                        src={appReq.content?.media[currentSlide]?.src}
                        video={imageRef}
                        comments={comments}
                        enlarge={showExpandedViewModal}
                        imageDimensions={imageDimensions}
                        setMediaIndex={setMediaIndex}
                        currentSlide={currentSlide}
                        setCommentCoords={setCommentCoords}
                        addingComment={addingComment}
                        sender={sender}
                        setSelectedCommentID={setSelectedCommentID}
                        handleLabelClick={handleLabelClick}
                        scrollToComment={scrollToComment}
                        setShowExpandedViewModal={setShowExpandedViewModal}
                        showExpandedViewModal={showExpandedViewModal}
                        selectedCommentID={selectedCommentID}
                        isPlaying={isPlaying}
                        setIsPlaying={setIsPlaying}
                      />
                    ) : appReq.content?.media[currentSlide]?.media ===
                      "html" ? (
                      <div
                        className=""
                        style={{
                          // overflow: 'hidden',
                          width: "100%",
                          maxHeight: "70vh",
                        }}
                      >
                        <div>
                          {/* <MdOutlinePhonelink
                            size={30}
                            className="text-secondary cursor-pointer mx-4"
                            onClick={() => setIsDesktopView(!isDesktopView)}
                          /> */}
                        </div>
                        <Iframe
                          onLoad={scrubIframeDoc}
                          scrubIframeDoc={scrubIframeDoc}
                          srcDoc={appReq.content?.media[currentSlide]?.src}
                          style={{
                            width: isDesktopView ? "200%" : "60%", // Increase width
                            height: isDesktopView ? "140vh" : "70vh",
                            border: "none",
                            margin: "0 auto",
                            transform: isDesktopView
                              ? "scale(0.5)"
                              : "scale(1.0)",
                            transformOrigin: "0 0",
                            position: "relative",
                            pointerEvents: "none",
                          }}
                          title={`Post ${currentSlide}`}
                          originalRef={iframeRef}
                          comment={comment}
                          onInferredClick={(e) => {
                            // can add click logic here. not sure if I still need it but will keep for now.
                          }}
                          addingcomment={addingComment}
                          setSegmentSelected={setSegmentSelected}
                          segmentselected={segmentSelected}
                          comments={comments.filter(
                            (comment) => comment.mediaIndex == currentSlide
                          )}
                          currentSlide={currentSlide}
                          setCommentCoords={setCommentCoords}
                          selectedCommentID={selectedCommentID}
                          setSelectedCommentID={setSelectedCommentID}
                          scrollToComment={scrollToComment}
                          user={
                            reviewer
                              ? reviewer
                              : user.email
                              ? user
                              : { email: reviewerEmail }
                          }
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "auto",
                          maxHeight: "65vh",
                          minHeight: "65vh",
                          display: "flex",
                        }}
                      >
                        <img
                          src={appReq.content?.media[currentSlide]?.src}
                          onLoad={handleImageLoaded}
                          alt={`Post ${currentSlide}`}
                          ref={imageRef}
                          className={
                            addingComment
                              ? "adding-comment rounded-lg"
                              : "rounded-lg"
                          }
                          style={{
                            objectFit: "contain",
                            margin: "auto",
                            display: "block",
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                          onClick={(e) => handleImageClick(e)}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      {requestNewLink ? (
        <ModalContent
          closeModal={() => {
            setRequestNewLink(false);
          }}
        >
          <div className="flex justify-center mt-2">
            {/* <div className="w-1/2"> */}
            <div className="flex flex-col justify-center">
              <p className="font-bold text-lg">
                Your approval link has expired.
              </p>
              <p>Click on the button to get a new link</p>
              <button
                onClick={handleGetNewLink}
                className="bg-primary text-white px-4 py-1 rounded-lg hover-shadow"
              >
                Get New Link
              </button>
              {/* </div> */}
            </div>
          </div>
        </ModalContent>
      ) : null}
    </div>
  );
};

const VideoPlayer = ({
  src,
  videoRef,
  video,
  comments,
  enlarge,
  imageDimensions,
  setMediaIndex,
  currentSlide,
  setCommentCoords,
  addingComment,
  sender,
  setSelectedCommentID,
  handleLabelClick,
  scrollToComment,
  setShowExpandedViewModal,
  showExpandedViewModal,
  selectedCommentID,
  isPlaying,
  setIsPlaying,
}) => {
  const [currentTime, setCurrentTime] = useRecoilState(currentTimeAtom);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [activeComments, setActiveComments] = useState([]);
  const newVideoRef = useRef(video);

  videoRef = videoRef ?? newVideoRef;

  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });

  comments = comments.filter((comment) => comment.mediaIndex == currentSlide);

  useEffect(() => {
    Logger.log('Something needs to be done here', selectedCommentID);
  }, [selectedCommentID]);

  useEffect(() => {
    if (videoRef.current) {
      setVideoDimensions({
        width: videoRef.current.offsetWidth,
        height: videoRef.current.offsetHeight,
      });
    }
  }, [videoRef.current?.offsetWidth, videoRef.current?.offsetHeight]);

  useEffect(() => {
    if (videoRef.current.currentTime !== currentTime) {
      videoRef.current.currentTime = currentTime;
    }
  }, [showExpandedViewModal]);

  useEffect(() => {
    setDuration(videoRef?.current?.duration);
    videoRef.current.volume = volume;
  }, [volume]);

  useEffect(() => {
    if (!addingComment) return;

    videoRef?.current?.pause();
  }, [addingComment]);

  const togglePlayPause = (e) => {
    if (addingComment && imageDimensions) {
      e.preventDefault();
      e.stopPropagation();
      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left; // x position within the element.
      const y = e.clientY - rect.top; // y position within the element.
      const relativeX = x / imageDimensions.width;
      const relativeY = y / imageDimensions.height;

      // Store these in state and use for the comment submission
      setMediaIndex(currentSlide); // Assuming currentSlide state holds the index of the current media
      setCommentCoords({
        x: relativeX,
        y: relativeY,
        absoluteX: e.clientX,
        absoluteY: e.clientY,
      });
      return;
    }
    const video = videoRef.current;

    if (video?.currentTime == duration) {
      setCurrentTime(0);
      video.currentTime = 0;
      video.play();
      setIsPlaying(true);
      return;
    }

    if (video) {
      if (video.paused) {
        video.play();
        setIsPlaying(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  };

  const formatTime = (seconds) => {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, '0');
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
    }
    return `${mm}:${ss}`;
  };

  const handleProgressChange = (e) => {
    const video = videoRef.current;
    const time = (e.target.value * video?.duration) / 100;
    video.currentTime = time;
  };

  const getProgress = () => {
    const video = videoRef.current;
    if (!video?.duration) return 0;
    return (video?.currentTime / video?.duration) * 100;
  };
  const handleTimeUpdate = () => {
    const currentTime = videoRef.current?.currentTime;
    setCurrentTime(currentTime);

    if (currentTime === duration) {
      setIsPlaying(false);
    }

    // Filter comments that should be active based on the current time
    const active = comments.filter((comment) => {
      return (
        currentTime >= comment?.coords?.videoTimestamp - 3 &&
        currentTime <= comment?.coords?.videoTimestamp + 3
      );
    });

    setActiveComments(
      active.map((comment) => ({
        ...comment,
        x: comment.coords.x * videoDimensions.width,
        y: comment.coords.y * videoDimensions.height,
      }))
    );
  };

  // Calculate the position of a comment on the progress bar
  const calculateCommentPosition = (timestamp) => {
    const video = videoRef.current;
    if (!video?.duration) return 0;
    const position = (timestamp / video.duration) * 100;
    return `${position - 5}%`;
  };

  const handleCommentClick = (timestamp) => {
    if (videoRef.current) {
      videoRef.current.currentTime = timestamp;
    }
  };

  return (
    <div className="bg-dashGray w-full mx-auto">
      <video
        ref={videoRef}
        src={src}
        className={addingComment ? 'adding-comment rounded-lg' : 'rounded-lg'}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={() => setDuration(videoRef.current.duration)}
        onClick={(e) => togglePlayPause(e)}
        style={{
          margin: '0 auto',
          boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
          height: enlarge ? '85vh' : '',
        }}
      />
      <div className="relative bottom-0 w-full flex justify-between items-center px-4 py-2 bg-gray-800 bg-opacity-75">
        <button
          onClick={togglePlayPause}
          className="focus:outline-none text-white"
        >
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        <div className="relative w-1/2">
          {!enlarge && (
            <>
              {comments.map((comment) => {
                return (
                  <div className="cursor-pointer" key={comment._id}>
                    {comment.sender.email === sender.email &&
                    sender?.profilePicURL ? (
                      <img
                        src={sender.profilePicURL}
                        onClick={() => {
                          handleCommentClick(comment.coords.videoTimestamp);
                          setSelectedCommentID(comment._id);
                          handleLabelClick(
                            comment?.resolved ? 'resolved' : 'comments'
                          );
                          scrollToComment(comment._id);
                          videoRef.current.currentTime =
                            comment.coords.videoTimestamp;
                          videoRef.current.pause();
                          setIsPlaying(false);
                        }}
                        style={{
                          left: calculateCommentPosition(
                            comment?.coords?.videoTimestamp
                          ),
                          borderRadius: '50%',
                          // position: 'absolute',
                          bottom: '24px',
                          zIndex: comment._id === selectedCommentID ? 100 : 99,
                          height: '32px' /* adjust as needed */,
                          width: '32px' /* adjust as needed */,
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          left: calculateCommentPosition(
                            comment?.coords?.videoTimestamp
                          ),
                          borderRadius: '50%',
                          // position: 'absolute',
                          bottom: '24px',
                          zIndex: comment._id === selectedCommentID ? 100 : 99,
                          height: '32px' /* adjust as needed */,
                          width: '32px' /* adjust as needed */,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setSelectedCommentID(comment._id);
                          handleLabelClick(
                            comment?.resolved ? 'resolved' : 'comments'
                          );
                          scrollToComment(comment._id);
                          videoRef.current.currentTime =
                            comment?.coords?.videoTimestamp;
                          videoRef.current.pause();
                          setIsPlaying(false);
                        }}
                      >
                        <AvatarComponent
                          classes="rounded-full"
                          useGravatar={false}
                          color={darkerShade(comment.bg, 0.55)}
                          background={comment.bg}
                          fontSize={16}
                          fontWeight={400}
                          className="text-primary cursor-pointer"
                          size={32}
                          initials={comment.sender.email
                            .substring(0, 1)
                            .toUpperCase()}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}

          <input
            type="range"
            min="0"
            max="100"
            value={getProgress()}
            onChange={handleProgressChange}
            className="w-full custom-range cursor-pointer"
          />
        </div>

        <div className="text-white w-24 text-center">
          <span>{formatTime(currentTime)}</span> /{' '}
          <span>{formatTime(duration)}</span>
        </div>
        {/* {!showExpandedViewModal && (
          <GoScreenFull
            size={30}
            className="cursor-pointer"
            color="white"
            onClick={() => {
              setShowExpandedViewModal(true);
              videoRef.current.pause();
              setIsPlaying(false);
            }}
          />
        )} */}
      </div>
    </div>
  );
};

export default ReviewApprovalRequest;
