import { useEffect, useState } from 'react';
import CheckboxWithTick from '../CheckBox';
import Icon from '../Icon';
import CompletedStep from '../SVGs/CompletedStep';
import plusIcon from '../../icons/plus-icon.png';

import Approver from './Approver';
import AddApproverModal from '../Modal/AddApproverModal';
import PendingStep from '../SVGs/PendingStep';

const ApprovalLevel = ({
  level = 1,
  setLevel,
  setLevelName,
  approvers,
  addApprover,
  removeApprover,
  isSubmitting,
  canHideComment = {},
  handleCanHideComment,
  lastLevel = true,
  disable,
  isEdit
}) => {
  const [open, setOpen] = useState(false);
  const [hideLevelComment, setHideLevelComment] = useState(canHideComment[level] || false);

  const [filteredApprovers, setFilteredApprovers] = useState([]);

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleModalOpen = () => {
    if (isSubmitting || disable) return;

    setOpen(true);
  };

  const handleHideComment = () => {
    if (disable) return;
    setHideLevelComment(!hideLevelComment);
    handleCanHideComment(level, !hideLevelComment);
  }

  const handleFirstLevelSetting = () => {
    if (!hideLevelComment) {
      handleCanHideComment(level, false);
    }
  }

  useEffect(() => {
    setFilteredApprovers(
      approvers.filter((approver) => approver.level === level)
    );
  }, [approvers]);

  

  return (
    <div>
      <div className="flex gap-2 items-center">
        {filteredApprovers.length ? (
          <CompletedStep size={24} className="" />
        ) : (
          <PendingStep size={24} className="stroke-gray-400" color="" />
        )}
        <span className="text-xl font-semibold">Level {level} Approval</span>
      </div>
      <div
        className={`flex flex-col border-primary ${
          filteredApprovers.length ? 'border-l-2' : ''
        } ml-3 pl-5 gap-1 pb-3`}
      >
        {filteredApprovers.map((approver, index) => {
          return (
            <Approver
              key={index}
              approver={approver}
              removeApprover={removeApprover}
              isEdit={isEdit}
            />
          );
        })}
        {/* {!lastLevel && (
          <div className="flex items-center gap-2">
            <CheckboxWithTick onSelect={handleHideComment} isSelected={hideLevelComment} disabled={disable}/>
            <span className="text-gray-500">Hide comments once approved</span>
          </div>
        )} */}
        
          <>
          <div className="flex items-center gap-2">
            <CheckboxWithTick onSelect={handleHideComment} isSelected={hideLevelComment} disabled={isEdit} />
            <span className="text-gray-500">Hide comments once approved</span>
          </div>
          {!isEdit && (<div
            className={`flex justify-center text-primary gap-2 ${disable ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            onClick={handleModalOpen}
          >
            {' '}
            <Icon src={plusIcon} /> Add approver
          </div>
           )}
        </>
       
      </div>
      <AddApproverModal
        closeModal={handleModalClose}
        isOpen={open}
        level={level}
        addApprover={addApprover}
        handleLevelSettings={handleFirstLevelSetting}
      />
    </div>
  );
};

export default ApprovalLevel;
