import { AvatarComponent } from 'avatar-initials';
import React, { useRef, useState, useEffect } from 'react';
import { darkerShade, generateUserColor } from "../../utils/darkerShade";
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import { MdEdit } from 'react-icons/md';
import { FaTrashAlt } from 'react-icons/fa';
import foramtDateComments from '../../utils/foramtDateComments';
import RepliesSection from '../Reply/RepliesSection';
import TextAreaComponent from '../Reply/TextArea';
import { Logger } from '../../utils/logger';
import Tick from '../SVGs/Tick';
import SendMessage from '../SVGs/SendMessage';
import ChevronDown from '../SVGs/ChevronDown';
import Attachment from '../SVGs/Attachment';
import CommentAttachment from './CommentFileAttachment';

const Comment = ({
  comment,
  index,
  group,
  reviewerEmail,
  users,
  commentRefs,
  selectedCommentID,
  updateComment,
  setSelectedCommentID,
  setCurrentSlide,
  setMediaIndex,
  appReq,
  imageRef,
  setIsPlaying,
  sender,
  scrollToComment,
  user,
  toggleResolved,
  deleteComment,
  convertSecondsToTimestamp,
  validMentionedUsers,
  validMentions,
  setSelectionId,
}) => {
  if (!commentRefs.current[comment._id]) {
    commentRefs.current[comment._id] = React.createRef();
  }

  const [showReplies, setShowReplies] = useState(false);
  const [replyCount, setReplyCount] = useState(comment.replies?.length);

  const isReviewer = reviewerEmail === comment.sender?.email;
  const isSelected = comment._id === selectedCommentID;
  const [isEditing, setIsEditing] = useState(false);
  const commentRef = useRef(null);

  const handleEdit = () => {
    setIsEditing(true);
    setTimeout(() => {
      commentRef.current?.focus();
    }, 0);
  };

  function highlightMentions(text, selected) {
    const mentionRegex =
      /(?<=\s|^)@[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z]{2,})(?=\s|$)|(?<=\s|^)@[a-zA-Z0-9_]+(?=\s|$)/g;

    const highlightedText = text.replace(mentionRegex, (mention) => {
      const user = validMentionedUsers[mention];
      return validMentions.has(mention)
        ? `<span class="mention-highlight ${
            selected ? 'text-gray-200' : 'text-blue-600'
          } hover:text-blue-300">${
            user.name ? `${user.name} (${user.email})` : user.email
          }</span>`
        : mention;
    });

    return highlightedText;
  }

  function highlightDocText(selections) {
    for (const selectionId of selections) {
      const elem = document.getElementById(selectionId);
      if (elem) {
        elem.style.backgroundColor = comment.bg; 
      }
    }
  }

  const handleCommentClick = () => {
    setSelectedCommentID(comment._id);
    setCurrentSlide(comment.mediaIndex);
    setMediaIndex(comment.mediaIndex);
    const currentMediaType = appReq.content?.media[comment.mediaIndex]?.media;

    if (currentMediaType == 'video') {
      setTimeout(() => {
        // Introduce a delay
        try {
          if (imageRef.current) {
            imageRef.current.currentTime = comment.coords.videoTimestamp;
            imageRef.current.pause();
            setIsPlaying(false);
          }
        } catch (error) {
          Logger.error('Error setting video currentTime:', error);
        }
      }, 20); // Adjust the delay as needed, 500ms as an example
    } else if (currentMediaType === 'doc') {
      setSelectionId(comment.id);
      highlightDocText(comment.selectionIds);
    } else if (currentMediaType === 'pdf') {
      setSelectionId(comment.selectionIds?.[0] ?? '');
      highlightDocText(comment.selectionIds);
    } else {
      setSelectionId(comment.id);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const cRef = commentRefs.current[comment._id];
      if (
        cRef?.current &&
        !cRef.current?.contains(event.target) &&
        selectedCommentID
      ) {
        setSelectedCommentID("");
      }
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [selectedCommentID]);

  const profilePicOrInitials =
    comment.sender?.email === sender.email && sender?.profilePicURL ? (
      <img
        src={sender.profilePicURL}
        style={{
          height: '32px' /* adjust as needed */,
          width: '32px' /* adjust as needed */,
        }}
      />
    ) : (
      <div
        style={{
          height: '32px' /* adjust as needed */,
          width: '32px' /* adjust as needed */,
        }}
      >
        <AvatarComponent
          classes="rounded-full"
          useGravatar={false}
          // color={darkerShade(comment.bg, 0.55)}
          // background={comment.bg}
          color={darkerShade(generateUserColor(comment.sender?.email), 0.55)}
          background={generateUserColor(comment.sender?.email)}
          fontSize={16}
          fontWeight={400}
          size={32}
          initials={comment.sender?.email?.substring(0, 1).toUpperCase()}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSelectedCommentID(comment._id);
            scrollToComment(comment._id);
          }}
        />
      </div>
    );

  return (
    <div
      className={`border rounded-lg py-4 px-5 flex flex-col gap-2 w-full group text-xs 
    ${isSelected ? "border-2 bg-gray-200 text-gray-600" : ""}
    `}
      ref={commentRefs.current[comment._id]}
      tabIndex={-1}
      key={index}
    >
      <div className="flex gap-2">
        <div className="border flex max-w-8 max-h-8 rounded-full items-center justify-center w-8 h-8 overflow-hidden">
          {profilePicOrInitials}
        </div>
        <div
          className="grow text-xs flex flex-col gap-2 cursor-pointer"
          onClick={handleCommentClick}
        >
          <div>
            <p className="text-sm font-semibold">
              {' '}
              {isReviewer
                ? 'You'
                : comment.sender?.user?.fullName
                ? `${comment.sender?.user?.fullName} (${comment.sender?.email})`
                : comment.sender?.email}
            </p>
            <p className="text-xs">
              {' '}
              {foramtDateComments(comment.dateCreated ?? new Date())}{' '}
              {comment.dateCreated != comment.lastUpdated &&
                ` (edited ${foramtDateComments(
                  comment.lastUpdated ?? new Date()
                )})`}
            </p>
          </div>
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: highlightMentions(comment.comment, isSelected),
              }}
              aria-disabled={comment.sender?.email !== reviewerEmail}
              id={comment._id}
            ></div>
          </div>
        </div>
        <div
          className={`rounded-full max-w-5 h-5 w-full border cursor-pointer ${
            comment.resolved ? 'bg-primary' : ''
          } flex items-center justify-center`}
          title={comment.resolved ? 'Resolved' : 'Not resolved'}
          onClick={() => {
            if (user?.email == reviewerEmail) {
              toggleResolved(comment._id);
            }
          }}
        >
          <Tick className="stroke-white w-3 h-3" />
        </div>
      </div>
      {comment.attachments?.length > 0 && (
        <div className="flex overflow-x-scroll no-scrollbar">
          {comment.attachments.map((attachment, idx) => {
            return <CommentAttachment key={idx} attachment={attachment} />;
          })}
        </div>
      )}

      {replyCount > 0 && (
        <div
          className={`flex w-full text-xs items-center justify-between ${
            isSelected ? "text-gray-500" : "text-gray-500"
          }`}
        >
          <div>{replyCount} comments</div>
          <div
            className={`${
              showReplies ? 'rotate-180' : ''
            } flex justify-center items-center border rounded-full p-1.5 w-6 h-6 cursor-pointer`}
            onClick={() => setShowReplies(!showReplies)}
          >
            <ChevronDown
              className={`${
                isSelected ? "stroke-gray-500" : "stroke-gray-300"
              }`}
            />
          </div>
        </div>
      )}
      <div className="hidden group-hover:block focus-within:block">
        <RepliesSection
          bg={comment.bg}
          email={reviewerEmail}
          isSelected={isSelected}
          replies={comment.replies}
          comment={comment}
          users={users}
          currentUser={user}
          validMentionedUsers={validMentionedUsers}
          validMentions={validMentions}
          showReplies={showReplies}
          setReplyCount={setReplyCount}
        />
      </div>
    </div>
  );
};

export default Comment;
