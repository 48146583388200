import CheckboxWithTick from '../CheckBox';
import Icon from '../Icon';
import CompletedStep from '../SVGs/CompletedStep';
import plusIcon from '../../icons/plus-icon.png';
import plusIconWhite from '../../icons/plus-white-icon.png';
import ApprovalLevel from './ApprovalLevel';
import { useState } from 'react';
import { Logger } from '../../utils/logger';

const AddApprovalRequestApprovers = ({
  approvers = [],
  setApprovers,
  approverLevelSettings = {},
  setApprovalLevelSettings,
  isSubmitting,
  preventModification = false,
  isEdit
}) => {
  const MAX_APPROVAL_LEVEL = 2;
  const maxLevel = Math.max(...approvers.map((approver) => approver.level), 0);
  const [approverLevel, setApproverLevel] = useState(maxLevel + 1);

  const handleAddApproverLevel = () => {
    if (preventModification) return;
    const levelApprover = approvers.find(
      (approver) => approver.level === approverLevel
    );

    if (!levelApprover) {
      return;
    }

    setApproverLevel(approverLevel + 1);
  };

  const handleAddApprover = (approver) => {
    if (preventModification) return;

    const existingApprover = approvers.find(
      (existingApprover) => existingApprover.email === approver.email
    );

    if (existingApprover) {
      setApprovers(
        approvers.map((existingApprover) =>
          existingApprover.email === approver.email
            ? approver
            : existingApprover
        )
      );
      return;
    }

    setApprovers([...approvers, approver]);
  };

  const handleRemoveApprover = (email) => {
    if (preventModification) return;

    const currentApprovers = approvers.filter(
      (approver) => approver.email !== email
    );

    const countLevelApprovers = currentApprovers.reduce((acc, approver) => {
      acc[approver.level] = acc[approver.level] ? acc[approver.level] + 1 : 1;
      return acc;
    }, {});

    let maxLevel = 1;

    for (let i = 1; i <= approverLevel; i++) {
      if (!countLevelApprovers[i]) {
        maxLevel = i;
        break;
      }
      maxLevel = i;
    }

    if (!countLevelApprovers[maxLevel]) {
      const newLevelSettings = {};
      for (let i = 1; i < maxLevel; i++) {
        newLevelSettings[i] = approverLevelSettings[i];
      }

      setApprovalLevelSettings(newLevelSettings);
    }

    setApprovers(
      currentApprovers.filter((approver) => approver.level <= maxLevel)
    );
    setApproverLevel(maxLevel);
  };

  const handleHideComment = (level, hide) => {
    if (preventModification) return;

    setApprovalLevelSettings({
      ...approverLevelSettings,
      [level]: hide
    })
  }

  return (
    <div className="flex flex-col gap-5">
      {Array.from({ length: approverLevel }, (_, index) => {
        return (
          <ApprovalLevel
            key={index}
            level={index + 1}
            setLevel={setApproverLevel}
            setLevelName={setApproverLevel}
            approvers={approvers}
            addApprover={handleAddApprover}
            removeApprover={handleRemoveApprover}
            isSubmitting={isSubmitting}
            lastLevel={index + 1 === approverLevel}
            handleCanHideComment={handleHideComment}
            canHideComment={approverLevelSettings}
            disable={preventModification}
            isEdit={isEdit}
          />
        );
      })}

    {!isEdit && (
      <div className="flex justify-center">
        <button
          className={`bg-primary text-white rounded-lg py-2.5 px-5 flex text-base font-semibold ${preventModification ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          onClick={handleAddApproverLevel}
          disabled={isSubmitting || preventModification}
        >
          <Icon src={plusIconWhite} /> Add approver level
        </button>
      </div>
    )}
    </div>
  );
};

export default AddApprovalRequestApprovers;
