import React, { useState } from "react";
import { useRecoilState } from "recoil";
import FormInput from "../Form/FormInput";
import { userAtom } from "../../recoil/userAtoms";
import smsIcon from "../../icons/smsIcon.png";
import emailOtpIcon from "../../icons/otp-email-icon.png";
import TwoFactorAuthModal from "../Modal/TwoFactorAuthModal";
import TwoFactorAuthOption from "../TwoFactorAuthOptions";
import axios from "axios";
import { emailMasker } from "../../utils/email-masker";
const Security = ({
  handlePasswordChange,
  handlePasswordUpdate,
  changePassword,
  setchangePassword,
  loading,
  setLoading,
}) => {
  const [user, setUser] = useRecoilState(userAtom)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSMS2FAEnabled, setIsSMS2FAEnabled] = useState(false);
  const [isEmail2FAEnabled, setIsEmail2FAEnabled] = useState(false);
  const [is2FAProcessing, setIs2FAProcessing] = useState(false);
  const [stage, setStage] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [twoFAChannel, setTwoFAChannel] = useState("");

  const [maskedNumber, setMaskedNumber] = useState("***-***-1234");
  const [maskedEmail, setMaskedEmail] = useState(emailMasker(user.email));

  const handleToggleSMS2FA = async () => {
    try {
      // Call backend API to toggle SMS 2FA

      setIsSMS2FAEnabled((prev) => !prev);
    } catch (error) {
      console.error("Failed to toggle SMS 2FA:", error);
    }
  };

  const handleSetupSMS = async () => {
    try {
      // Call backend API to set up SMS 2FA
      setIsModalOpen(true);
    } catch (error) {
      console.error("Failed to set up SMS 2FA:", error);
    }
  };

  const handleDeactivateSMS = async () => {
    try {
      // Call backend API to deactivate SMS 2FA
      setIsSMS2FAEnabled(false);
    } catch (error) {
      console.error("Failed to deactivate SMS 2FA:", error);
    }
  };

  const handleToggleEmail2FA = async () => {
    try {
      // Call backend API to toggle Email 2FA
      setIsEmail2FAEnabled((prev) => !prev);
    } catch (error) {
      console.error("Failed to toggle Email 2FA:", error);
    }
  };

  const handleSetupEmail = async () => {
    try {
      // Call backend API to set up Email 2FA
      setLoading(true);
      const { data } = await axios.post(`/api/auth/2fa/setup?firebaseAuthUUID=${user.firebaseAuthUUID}&channel=EMAIL`);

      setMaskedEmail(data.maskedEmail);
      setTwoFAChannel("EMAIL");
      setStage(2);
      setIsModalOpen(true);
      setIsEmail2FAEnabled(true);
    } catch (error) {
      console.error("Failed to set up Email 2FA:", error);
    }
  };

  const handleDeactivateEmail = async () => {
    try {
      // Call backend API to deactivate Email 2FA
      await axios.put(`/api/auth/2fa/disable?firebaseAuthUUID=${user.firebaseAuthUUID}&channel=EMAIL`);

      setUser({
        ...user,
        email2FAEnabled: false
      })
      
    } catch (error) {
      console.error("Failed to deactivate Email 2FA:", error);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setStage(0);
    setPhoneNumber("");
  };

  return (
    <div className="flex flex-col">
      <div className="pb-4 border-b">
        <h4 className="text-gray-900 font-semibold text-lg">Profile</h4>
        <p className="text-gray-600 text-sm">
          Update your photo and personal details here
        </p>
      </div>
      <div className="flex flex-box border-b py-3 items-center">
        <div className="w-72">
          <h4 className="text-gray-700 font-semibold m-0">Old Password</h4>
        </div>
        <div className="flex flex-row gap-4 max-w-[350px] w-full">
          <FormInput
            value={changePassword.password}
            type={"password"}
            placeholder={"Old password"}
            onChange={handlePasswordChange}
            id="password"
          />
        </div>
      </div>
      <div className="flex flex-box border-b py-5 items-center">
        <div className="w-72">
          <h4 className="text-gray-700 font-semibold m-0">New Password</h4>
        </div>
        <div className="flex flex-row gap-4 max-w-[350px] w-full">
          <FormInput
            value={changePassword.newPassword}
            type={"password"}
            placeholder={"New password"}
            onChange={handlePasswordChange}
            id="newPassword"
          />
        </div>
      </div>
      <div className="flex flex-box border-b py-5 items-center">
        <div className="w-72">
          <h4 className="text-gray-700 font-semibold m-0">
            Confirm New Password
          </h4>
        </div>
        <div className="flex items-center max-w-[350px] w-full">
          <FormInput
            value={changePassword.confirmNewPassword}
            type={"password"}
            placeholder={"Confirm new password"}
            onChange={handlePasswordChange}
            id="confirmNewPassword"
          />
        </div>
      </div>
      <div className="flex justify-end mt-6">
        <button
          className="bg-primary-600 text-white py-2.5 px-5 rounded-md"
          onClick={handlePasswordUpdate}
        >
          Update Password
        </button>
      </div>

      <div className="border-gray-200 pt-6">
        <div className="pb-4 border-b">
          <h3 className="text-lg font-semibold mb-2">
            Two-factor Authentication
          </h3>
          <p className="text-sm text-gray-600">
            Use two-factor authentication (2FA) to add an extra layer of
            security to your account.
          </p>
        </div>
        <div className="flex justify-between">
          {/* <TwoFactorAuthOption
            isEnabled={isSMS2FAEnabled}
            type="SMS"
            description="Registering your mobile number for authentication will help secure your account from unauthorized access."
            maskedValue={maskedNumber}
            icon={smsIcon}
            onToggle={handleToggleSMS2FA}
            onSetup={handleSetupSMS}
            onDeactivate={handleDeactivateSMS}
          /> */}
          <TwoFactorAuthOption
            isEnabled={user.email2FAEnabled}
            type="Email"
            description="Registering your email address for authentication will help secure your account from unauthorized access."
            maskedValue={maskedEmail}
            icon={emailOtpIcon}
            onToggle={handleToggleEmail2FA}
            onSetup={handleSetupEmail}
            onDeactivate={handleDeactivateEmail}
          />
        </div>
      </div>
      {isModalOpen && (
        <TwoFactorAuthModal
          onClose={handleCloseModal}
          loading={loading}
          setLoading={setLoading}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          setIsSMS2FAEnabled={setIsSMS2FAEnabled}
          isSMS2FAEnabled={isSMS2FAEnabled}
          setMaskedNumber={setMaskedNumber}
          maskedNumber={maskedNumber}
          setIsEmail2FAEnabled={setIsEmail2FAEnabled}
          setMaskedEmail={setMaskedEmail}
          maskedEmail={maskedEmail}
          stage={stage}
          setStage={setStage}
          twoFAChannel={twoFAChannel}
          isEmail2FAEnabled={isEmail2FAEnabled}
          resendCode={handleSetupEmail}
        />
      )}
    </div>
  );
};

export default Security;
