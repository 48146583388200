import { AvatarComponent } from 'avatar-initials';
import TextAreaComponent from './TextArea';
import { FaTrashAlt } from 'react-icons/fa';
import axios from 'axios';
import { useRef, useState } from 'react';
import { darkerShade } from '../../utils/darkerShade';
import foramtDateComments from '../../utils/foramtDateComments';
import CommentAttachment from '../Comment/CommentFileAttachment';

const Reply = ({
  bg,
  email,
  content,
  refCard,
  id,
  users,
  loadReplies,
  reviewerEmail,
  reply,
  currentUser,
  validMentionedUsers,
  validMentions,
  attachments,
}) => {
  const [edit, setEdit] = useState(false);
  const textareaRef = useRef(null);

  function highlightMentions(text) {
    const mentionRegex =
      /(?<=\s|^)@[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z]{2,})(?=\s|$)|(?<=\s|^)@[a-zA-Z0-9_]+(?=\s|$)/g;

    const highlightedText = text.replace(mentionRegex, (mention) => {
      const user = validMentionedUsers[mention];
      return validMentions.has(mention)
        ? `<span class="mention-highlight text-blue-600 hover:text-blue-300">${
            user?.name ? `${user?.name} (${user?.email})` : user.email
          }</span>`
        : mention;
    });

    return highlightedText;
  }

  const deleteReply = async (id) => {
    await axios.delete(`/api/comments/replies/${id}`);
    await loadReplies();
  };

  const updateReply = async (id, content, mentions) => {
    await axios.put(`/api/comments/replies/${id}`, {
      content,
      email: reviewerEmail,
      mentions,
    });
    await loadReplies();
  };

  const displayName = reply.user?.fullName
    ? `${reply.user.fullName}`
    : email === reviewerEmail
    ? 'You'
    : reply.email;

  return (
    <div
      ref={refCard}
      className={`text-xs bg-gray-50 py-2 pr-2 rounded h-fit group/reply ${
        email === reviewerEmail ? 'reply' : ''
      }`}
    >
      <div className="flex flex-col relative h-fit">
        <div className="flex flex-col gap-1 mb-2">
          <div className="flex items-center gap-2">
            <div
              className="border group/avatar"
              style={{
                borderRadius: '50%',
                height: '25px',
                width: '25px',
                minWidth: '25px',
              }}
            >
              {reply.user?.profilePicURL ? (
                <img
                  src={reply.user?.profilePicURL}
                  style={{
                    borderRadius: '50%',
                    height: '25px',
                    width: '25px',
                  }}
                  alt={displayName}
                />
              ) : (
                <AvatarComponent
                  classes="rounded-full"
                  useGravatar={false}
                  color={darkerShade(bg, 0.55)}
                  background={bg}
                  fontSize={16}
                  fontWeight={400}
                  size={32}
                  initials={reply.email.substring(0, 1).toUpperCase()}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              )}
              <div className="absolute top-0 left-6 w-max bg-blue-400 text-white text-sm rounded-lg p-2 shadow-lg opacity-0 group-hover/avatar:opacity-100 transition-opacity">
                {reply.user?.email}
              </div>
            </div>
            <div className="flex flex-col">
              <span className="font-semibold text-sm">{displayName}</span>
              <span className="text-xs text-gray-500">
                {foramtDateComments(reply.createdAt ?? new Date())}{' '}
                {reply.createdAt !== reply.updatedAt &&
                  `(edited ${foramtDateComments(
                    reply.updatedAt ?? new Date()
                  )})`}
              </span>
            </div>
          </div>
        </div>

        <div className="ml-9">
          {edit ? (
            <TextAreaComponent
              initialValue={content}
              id={id}
              closeEdit={() => setEdit(false)}
              users={users}
              loadReplies={loadReplies}
              disable={email !== reviewerEmail}
              currentUser={currentUser}
              reviewerEmail={reviewerEmail}
              textareaRef={textareaRef}
              saveFn={updateReply}
            />
          ) : (
            <div
              className=""
              onClick={() => email === reviewerEmail && setEdit(true)}
              dangerouslySetInnerHTML={{ __html: highlightMentions(content) }}
              aria-disabled={email !== reviewerEmail}
              id={id}
            ></div>
          )}
        </div>
      </div>
      <div className="flex justify-between items-start mt-2">
        <div className="flex overflow-x-scroll no-scrollbar ml-6 gap-2">
          {attachments.map((attachment, idx) => {
            return (
              <div className="flex gap-2" key={idx}>
                <CommentAttachment
                  attachment={attachment}
                  pos={idx + 1}
                />
              </div>
            );
          })}
        </div>
        <div className="mt-2">
          {edit ? (
            <p
              className="cursor-pointer text-blue-600 hover:text-blue-800"
              onClick={() => {
                setEdit(false);
              }}
            >
              Cancel
            </p>
          ) : email === reviewerEmail ? (
            <FaTrashAlt
              className="bg-blue reply-trash hidden group-hover/reply:block cursor-pointer"
              onClick={() => email === reviewerEmail && deleteReply(id)}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Reply;
