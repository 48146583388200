import { FaXmark } from 'react-icons/fa6';
import Attachment from '../SVGs/Attachment';
import { truncateString } from '../../utils/stringUtils';

const CommentFileAttachment = ({ attachment, pos = 1, deleteAction, showSize }) => {
    const formatFileSize = (sizeInMB) => {
      if (sizeInMB < 1) {
        return `${(sizeInMB * 1024).toFixed(2)} KB`;
      }
      return `${sizeInMB.toFixed(2)} MB`;
    };

  return (
    <div className="flex items-center gap-2 p-1 bg-gray-100 border rounded text-[10px]">
      <div className="flex items-center gap-2 px-1 rounded">
        <a
          href={attachment.url}
          target="_blank"
          className="flex gap-1 text-nowrap justify-center items-center"
        >
          <Attachment size="15" />{" "}
          <span className="no-wrap">{truncateString(attachment.name) ?? `Attachment ${pos}` }</span>
        </a>
        {showSize ? (
          <span className="text-primary">
            {attachment?.size ? formatFileSize(attachment?.size) : "2 MB"}
          </span>
        ) : (
          deleteAction && (
            <span className="cursor-pointer" onClick={deleteAction}>
              <FaXmark className="text-primary" />
            </span>
          )
        )}
      </div>
    </div>
  );
};

export default CommentFileAttachment;
