import { useEffect, useState } from "react";
import axios from "axios";
import ZapierIntegration from "../Integrations/integration-items/ZapierIntegration";
import $ from "jquery";
import IntegrationCard from "../Integrations/IntegrationCard";
import zapier from "../../icons/zapier.png";
import { useNavigate } from "react-router-dom";

const integrationMapper = {
  zapier: {
    title: "Zapier",
    description: "Build custom automations and integrations with apps",
    icon: zapier,
    link: "https://zapier.com/engine/auth/start/App206209CLIAPI@1.0.0/?context=11586067",
    buttonText: "View Integration",
  },
};

const Integrations = ({ user, setView }) => {
  const [integrations, setIntegrations] = useState([]);
  const [isZapierEnabled, setZapierEnabled] = useState(true);
  const navigate = useNavigate()

  useEffect(() => {
    async function fetchIntegrations() {
      const response = await axios
        .get(
          `/api/users/integrations?firebaseAuthUUID=${user?.firebaseAuthUUID}`
        )
        .catch((e) => ({ e }));
      if (response.data) {
        setIntegrations(response.data.integrations || []);
      }
    }

    fetchIntegrations();
  }, [user]);

  const handleLookingForMoreClick = () => {
    setView("contact-us");
    navigate("/contact-us");
    // $("#integrationsListCloseBtn").click();
    // $("/contact-us").click();
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between pb-4 border-b">
        <div>
          <h4 className="text-gray-900 font-semibold text-lg">Integrations</h4>
          <p className="text-gray-600 text-sm">Manage your integrations here</p>
        </div>
        <div>
          <button
            className="w-full py-2 px-4 bg-primary text-white hover-shadow cursor-pointer rounded-xl p-2 font-bold w-"
            onClick={handleLookingForMoreClick}
          >
            Looking for more?
          </button>
        </div>
      </div>
      <div className="flex flex-wrap flex-row gap-4">
        {integrations.map((integration) => (
          <IntegrationCard
            title={integrationMapper[integration.type]?.title}
            description={integrationMapper[integration.type]?.description}
            icon={integrationMapper[integration.type]?.icon}
            isEnabled={integration.active}
            onToggle={(e) => {
              if (e.target.checked) {
                window.open(integrationMapper[integration.type]?.link, "_blank")
              }
            }}
            buttonText="View Integration"
            integration={integration}
          />
        ))}
      </div>
    </div>
  );
};

export default Integrations;
