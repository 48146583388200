import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
const { persistAtom } = recoilPersist();

export const organizationAtom = atom({
  key: 'organizations',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const activeOrganizationAtom = atom({
  key: 'activeOrganization',
  default: {},
  effects_UNSTABLE: [persistAtom],
});
