import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import ModalContent from './ModalContent';
import Icon from '../Icon';
import uploadIcon from '../../icons/upload-cloud.png';
import { Logger } from '../../utils/logger';
import uploadToS3 from '../../utils/uploadToS3';
import { userAtom } from '../../recoil/userAtoms';
import emailIcon from '../../icons/email-icon.png';
import plusIcon from '../../icons/plus-icon.png';
import FormInput from '../Form/FormInput';
import CheckboxWithTick from '../CheckBox';
import useError from '../../hooks/useError';
import { activeOrganizationAtom } from '../../recoil/organization-atom';

const CreateWorkspaceModal = ({
  closeModal,
  isOpen,
  fetchWorkspaces,
  data,
  isEdit,
  isViewMembers, setIsViewMembers
}) => {
  const [user] = useRecoilState(userAtom);
  const [activeOrganization] = useRecoilState(activeOrganizationAtom);
  const { errors, addError, clearErrors, removeError } = useError([
    'email',
    'firstName',
    'lastName',
  ]);

  const workspacePersist = JSON.parse(localStorage.getItem('recoil-persist'));
  const workspace = workspacePersist?.workspaces?.find(
    (w) => w._id === data._id
  );

  const [workspaceData, setWorkspaceData] = useState({
    workspaceName: data?.name ?? workspace?.name ?? '',
    color: data?.color ?? '#0D76DB',
  });
  const [imageData, setImageData] = useState([]);
  const [stage, setStage] = useState(isViewMembers? 2: 1);
  const [addWorkspaceMember, setAddWorkspaceMember] = useState(true);
  const [workspaceMembers, setWorkspaceMembers] = useState(
    data?.workspaceMembers ?? []
  );
  const [workspaceMemberData, setWorkspaceMemberData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  useEffect(() => {
    if (isViewMembers) {
      setStage(2)
    }
  }, [isViewMembers])

  useEffect(() => {
    if (isEdit) {
      setWorkspaceData({
        workspaceName: data?.name ?? workspace?.name ?? '',
        color: data?.color ?? '#0D76DB',
      });
      setWorkspaceMembers(data?.workspaceMembers ?? []);
    }
  }, [data]);

  const validateWorkspaceMemberDetails = (data) => {
    clearErrors();

    if (!checkAllFieldsCompleted()) {
      data.firstName === '' && addError('firstName', 'First name is required');
      data.lastName === '' && addError('lastName', 'Last name is required');
      data.email === '' && addError('email', 'Email is required');

      return true;
    }

    // validate email
    const email = data.email;
    const emailValid = /\S+@\S+\.\S+/.test(email);

    if (!emailValid) {
      addError('email', 'Please enter a valid email address');
      return true;
    }
  };

  const [selectedWorkspaceColor, setSelectedWorkspaceColor] =
    useState('#0D76DB');

  const handleCloseAddWorkspaceModal = (current) => {
    if (!current || isViewMembers) {
      setStage(1);
      setIsViewMembers(false)
      closeModal();
      return;
    }

    if ((isEdit && stage == 2)){
      setStage(1);
      clearErrors(['workspaceName']);
      closeModal();
    }

    const prev = (current ?? 0) - 1;
    switch (prev) {
      case 0:
        setStage(1);
        clearErrors(['workspaceName']);
        closeModal();
        break;
      case 1:
        switch (addWorkspaceMember) {
          case true:
            setStage(prev);
            setIsViewMembers(false)
            break;
          default:
            setAddWorkspaceMember(true);
        }

        clearErrors(['firstName', 'lastName', 'email']);
        break;
      default:
        break;
    }
  };

  const handleNextAction = () => {
    if (stage === 1) {
      if (!workspaceData.workspaceName) {
        addError('workspaceName', 'Add workspace name');
        return;
      }
      if (isEdit) {
        return handleAddWorkspace();
      }
        setStage(2)
    } else if (stage === 2) {
      const fieldsCompleted = checkAllFieldsCompleted();
      // if (!fieldsCompleted && workspaceMembers.length === 0) {
      //   return;
      // } else {
      if (fieldsCompleted) {
        const error = validateWorkspaceMemberDetails(workspaceMemberData);
        if (error) return;

        setWorkspaceMembers([...workspaceMembers, workspaceMemberData]);
        setWorkspaceMemberData({
          firstName: '',
          lastName: '',
          email: '',
        });
      }

      if (!addWorkspaceMember) {
        handleAddWorkspace();
      }

      setAddWorkspaceMember(false);
    }
  };

  const availableColors = [
    { label: 'primary', code: '#0D76DB' },
    { label: 'red', code: '#FF4F00' },
    { label: 'magenta', code: '#E55DFF' },
    { label: 'violet', code: '#8247F5' },
    { label: 'cyan', code: '#09E8F0' },
    { label: 'electric lime', code: '#CCF000' },
    { label: 'bright yellow', code: '#F8E436' },
    { label: 'orange', code: '#FFA600' },
  ];

  const selectColor = (color) => {
    setSelectedWorkspaceColor(color);
  };

  const handleChange = (name, value) => {
    removeError(name);

    setWorkspaceData({ ...workspaceData, [name]: value });
  };

  const addImageData = (image) => {
    setImageData((prevImages) => [image]);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files); // Convert files to an array

    // Process each file in the files array
    files.forEach((file) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Use a function that sets an array of image data, instead of a single image data state
        addImageData({ name: file.name, data: reader.result });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    });
  };

  const uploadImageToS3 = async () => {
    Logger.log(imageData);
    Logger.log(imageData.length);
    if (imageData.length == 0) {
      return [{ url: data?.icon ?? '' }];
    }
    // Assuming uploadToS3 is a function that uploads a file to S3 and returns the URL
    const url = await uploadToS3(imageData, user);
    return url;
  };

  const handleAddWorkspace = async () => {
    try {
      const workspaceIconURL = await uploadImageToS3();

      const response = isEdit
        ? await axios.put(`/api/workspace/editWorkspace/${data._id}`, {
            workspaceName: workspaceData.workspaceName,
            color: workspaceData.color,
            workspaceIcon: workspaceIconURL[0].url,
            workspaceMembers,
          })
        : await axios.post('/api/workspace/createWorkspace', {
            firebaseAuthUUID: user?.firebaseAuthUUID,
            workspaceName: workspaceData.workspaceName,
            color: workspaceData.color,
            workspaceIcon: workspaceIconURL[0].url, // This is optional based on your setup
            workspaceMembers,
            organizationId: activeOrganization?._id,
          });

      if ([200, 201].includes(response.status)) {
        Logger.log('Successfully created new workspace', response.data);
        // Refetch workspaces to update the UI
        fetchWorkspaces();
        closeAddWorkspaceModal();
      } else {
        Logger.error('Failed to create workspace: ', response.data);
      }
    } catch (error) {
      Logger.error('An error occurred while creating the workspace:', error);
    }
    // setIsLoading(false);

    // Perform the rest of your logic, such as sending this information to the backend
  };

  const checkAllFieldsCompleted = () => {
    if (
      workspaceMemberData.firstName &&
      workspaceMemberData.lastName &&
      workspaceMemberData.email
    ) {
      return true;
    }
    return false;
  };

  const handleRemoveWorkspaceMember = (index) => {
    const filteredWorkspaceMembers = workspaceMembers.filter(
      (_, i) => i !== index
    );

    setWorkspaceMembers(filteredWorkspaceMembers);
  };

  const handleAddWorkspaceMember = () => {
    setIsViewMembers(false)
    if (!addWorkspaceMember) {
      setAddWorkspaceMember(true);

      return;
    }

    const error = validateWorkspaceMemberDetails(workspaceMemberData);
    if (error) return;

    setWorkspaceMembers([...workspaceMembers, workspaceMemberData]);
    setWorkspaceMemberData({
      firstName: '',
      lastName: '',
      email: '',
    });

    return true;
  };

  const handleWorkspaceMemberChange = (e) => {
    removeError(e.target.name);

    setWorkspaceMemberData({
      ...workspaceMemberData,
      [e.target.name]: e.target.value,
    });
  };

  const closeAddWorkspaceModal = () => {
    // reset all states
    setWorkspaceData({
      workspaceName: '',
      color: '#0D76DB',
    });
    setImageData([]);
    setStage(1);
    setAddWorkspaceMember(true);
    setWorkspaceMembers([]);
    setIsViewMembers(false)
    setWorkspaceMemberData({
      firstName: '',
      lastName: '',
      email: '',
    });

    closeModal();
  };

  return isOpen ? (
    <ModalContent
      title={stage > 1 ? 'Members' : 'Workspace'}
      className={'max-w-[480px]'}
      src={stage > 1 ? emailIcon : ''}
      closeModal={closeAddWorkspaceModal}
      openModal={stage}
    >
      <div className="flex flex-col items-center gap-4 text-sm">
        {stage === 1 ? (
          <>
            <p>
              A workspace is a dedicated folder of approvals for each client,
              department or project.
            </p>
            <label
              htmlFor="fileInput"
              className={`w-24 h-24 bg-dashGray hover-shadow cursor-pointer ${
                imageData[0]?.data || data?.icon
                  ? 'rounded-full overflow-hidden'
                  : ''
              }`}
            >
              <div className="h-full w-full flex justify-center items-center">
                <Icon
                  src={imageData[0]?.data || data?.icon || uploadIcon}
                  alt="Upload"
                  className={
                    imageData[0]?.data || data?.icon ? 'w-full h-full ' : ''
                  }
                />
                <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  onChange={handleImageChange}
                />
              </div>
            </label>
            <div className="text-sm text-center">
              Add image to this workspace
            </div>
            <FormInput
              id={'workspaceName'}
              label={'Workspace Name'}
              type={'text'}
              placeholder={'Enter workspace name'}
              onChange={(e) => handleChange('workspaceName', e.target.value)}
              value={workspaceData.workspaceName}
              name={'workspaceName'}
              hint={errors.workspaceName}
              error={true}
            />
            <div className="w-full flex flex-col gap-4">
              <p>Choose workspace color</p>
              <div className="flex justify-between">
                {availableColors.map((colorObj, index) => (
                  <div
                    className={`border w-fit h-fit rounded-full ${
                      workspaceData.color === colorObj.code
                        ? 'border-2 border-primary'
                        : ''
                    }`}
                    key={index}
                  >
                    <div
                      className="flex items-center justify-center w-6 h-6 rounded-full m-0.5 cursor-pointer"
                      style={{ backgroundColor: colorObj.code }}
                      onClick={() => handleChange('color', colorObj.code)}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : stage === 2 ? (
          <>
            <p>
              Members of your team will get access to the workspace, be able to
              view projects, and can be assigned feedback from approvers.
            </p>
            {addWorkspaceMember && !isViewMembers ? (
              <div className="w-full flex flex-col gap-4">
                <FormInput
                  id={'firstname'}
                  label={'First Name'}
                  type={'text'}
                  placeholder={'Enter first name'}
                  onChange={handleWorkspaceMemberChange}
                  value={workspaceMemberData.firstName}
                  name={'firstName'}
                  hint={errors.firstName}
                  error={true}
                />
                <FormInput
                  id={'lastname'}
                  label={'Last Name'}
                  type={'text'}
                  placeholder={'Enter last name'}
                  onChange={handleWorkspaceMemberChange}
                  value={workspaceMemberData.lastName}
                  name={'lastName'}
                  hint={errors.lastName}
                  error={true}
                />
                <FormInput
                  id={'email'}
                  label={'Email Address'}
                  type={'text'}
                  placeholder={'email@swiftapprove.com'}
                  onChange={handleWorkspaceMemberChange}
                  value={workspaceMemberData.email}
                  name={'email'}
                  hint={errors.email}
                  error={true}
                />
              </div>
            ) : (
              <div className="w-full flex flex-col gap-4">
                {workspaceMembers.map((workspaceMember, index) => {
                  return (
                    <div className={`flex w-full justify-between ${!workspaceMember.inviteStatus ? 'bg-[#edf3ff] py-1.5 rounded-lg' : ''}`} key={index}>
                      <div className="flex gap-3">
                        <div className="border w-10 h-10 rounded-full flex items-center justify-center font-semibold text-base">
                          {workspaceMember?.firstName?.[0]}
                          {workspaceMember?.lastName?.[0]}
                        </div>
                        <div className="flex flex-col">
                          <p className="text-sm font-semibold">
                            {workspaceMember.firstName}{' '}
                            {workspaceMember.lastName}
                          </p>
                          <p className="">{workspaceMember.email}</p>
                        </div>
                      </div>
                      <CheckboxWithTick
                        onSelect={() => handleRemoveWorkspaceMember(index)}
                        partial={true}
                        forced
                      />
                    </div>
                  );
                })}
              </div>
            )}
            <div className="w-full flex">
              <button
                className="text-primary rounded-lg py-2.5 flex items-center gap-1.5"
                onClick={handleAddWorkspaceMember}
              >
                <Icon src={plusIcon} alt={'Add member'} />
                Add another
              </button>
            </div>
          </>
        ) : null}

        <div className="flex justify-between w-full gap-4 mt-8 mb-4">
          <button
            className="border rounded-lg py-2.5 px-5 w-full"
            onClick={() => handleCloseAddWorkspaceModal(stage)}
          >
            {(isEdit && stage == 2)? 'Cancel' :stage > 1  ? 'Back' : 'Cancel'}
          </button>
          {!isViewMembers && <button
            className="bg-primary text-white rounded-lg py-2.5 px-5 w-full"
            onClick={handleNextAction}
          >
            {stage > 1
              ? addWorkspaceMember
                ? 'Complete'
                : 'Send Invite'
              : isEdit? 'Save': 'Next'}
          </button>}
        </div>
      </div>
    </ModalContent>
  ) : null;
};

export default CreateWorkspaceModal;
