import axios from 'axios';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../recoil/userAtoms';
import { Logger } from '../../utils/logger';
import { activeOrganizationAtom } from '../../recoil/organization-atom';

const DAY_TO_SECONDS = 86400000;

const LinkExpiring = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const [organization, setActiveOrganization] = useRecoilState(activeOrganizationAtom)

  const [isLinkExpiringEnabled, setIsLinkExpiringEnabled] = useState(organization?.settings?.linkExpiry ? true: false);
  const [selectedDuration, setSelectedDuration] = useState(((organization?.settings?.linkExpiry ?? 0) / DAY_TO_SECONDS));

  const handleToggle = async () => {
    setIsLinkExpiringEnabled((prevState) => !prevState);
    if (isLinkExpiringEnabled) {
      await updateLinkExpiring(0);
    }
  };

  const handleDurationSelect = async (duration) => {
    setSelectedDuration(duration);
    await updateLinkExpiring(duration);
  };

  const updateLinkExpiring = async (expiryDuration) => {
    try {
      const { data } = await axios.put(`/api/organizations/${organization._id}/settings/link-expiry?firebaseAuthUUID=${user.firebaseAuthUUID}`, {
        linkExpiry: expiryDuration * DAY_TO_SECONDS
      });

      setUser({ ...user, linkExpiry: data.linkExpiry });
      setActiveOrganization({ ...organization, settings: { ...organization.settings, linkExpiry: data.linkExpiry } });
    } catch (error) {
      setSelectedDuration(((organization?.settings?.linkExpiry ?? 0) / DAY_TO_SECONDS));
      setIsLinkExpiringEnabled(organization?.settings?.linkExpiry ? true: false);
      Logger.error(error);
    }
  }

  return (
    <div className="mt-2">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-md items-center font-inter text-[16px] font-semibold leading-[24px]">
            <span className="mr-2 text-xl font-bold">•</span>Link Expiring
          </h3>
          <p className="text-sm text-gray-500 mb-4">
            Enable or disable link expiring feature
          </p>
        </div>
        <div className="flex items-center gap-4">
          <span className="text-sm font-semibold">
            {isLinkExpiringEnabled ? 'Enabled' : 'Disabled'}
          </span>
          <label className="relative inline-block w-10 h-6">
            <input
              type="checkbox"
              className="peer sr-only"
              checked={isLinkExpiringEnabled}
              onChange={handleToggle}
            />
            <span className="absolute top-0 left-0 w-10 h-6 bg-gray-300 rounded-full transition peer-checked:bg-blue-500"></span>
            <span className="absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition peer-checked:translate-x-4"></span>
          </label>
        </div>
      </div>
      {isLinkExpiringEnabled && (
        <div className="mt-6 flex justify-end">
          <p className="font-medium mr-4 mt-2 items-center">How long?</p>
          <div
            className="flex border border-gray-300 h-10 rounded-lg shadow-xs overflow-hidden"
            style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)' }}
          >
            <div
              className={`px-3 py-2 text-center cursor-pointer ${
                selectedDuration === 7 ? 'bg-gray-200' : ''
              }`}
              onClick={() => handleDurationSelect(7)}
            >
              7 days
            </div>
            <div className="border-l border-gray-300"></div>
            <div
              className={`px-3 py-2 text-center cursor-pointer ${
                selectedDuration === 14 ? 'bg-gray-200' : ''
              }`}
              onClick={() => handleDurationSelect(14)}
            >
              14 days
            </div>
            <div className="border-l border-gray-300"></div>
            <div
              className={`px-3 py-2 text-center cursor-pointer ${
                selectedDuration === 28 ? 'bg-gray-200' : ''
              }`}
              onClick={() => handleDurationSelect(28)}
            >
              28 days
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkExpiring;
